










import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import InputField from "components/form/InputField"

let id = 0
@Component({})
export default class FileField extends InputField<File | null> {
	@Prop({required: false, type: String}) message!: string

	id = ""
	uploadMessage = ""

	created() {
		this.id = `file-field-${id}`
		id += 1
	}

	mounted() {
		this.uploadMessage = this.contents && this.contents.name || this.message
	}

	fileSelected() {
		const files = (this.$el.querySelector("#" + this.id) as HTMLInputElement).files
		if (files && files.length) {
			this.contents = files[0]
			this.uploadMessage = files[0].name
		}
	}
}
