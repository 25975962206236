import {restApiRootUrl, mutationRootUrl} from "api/settings"
import {Pension} from "api/models/participant/pension"
import {CommunicationContact} from "api/models/participant"

import {pension as convertPension} from "store/participant/conversion"
import {one, mandatory} from "lib/import/extract"
import {post, del, getJson, postResponse} from "api/request"
import {IncomeItem, PensionEventPayload, PlannerChoicesPayload, PlannerResult} from "store/pensiondetails/types"
import {RiskProfileForCalculation} from "store/state/types"
import {toaster} from "custom/toaster"

const xhrPostForm = async <T>(url: string, data: FormData) => {
	return new Promise<T>(async (resolve, reject) => {
		const response = await postResponse(url, data)
		if (response.ok) {
			resolve(await response.json())
		} else {
			reject({
				status: response.status,
				statusText: response.statusText,
				statusBody: await response.json()
			})
		}
	})
}

export default class ParticipantController {
	private readonly convert = mandatory(one(convertPension))

	async postNPR(file: File): Promise<Pension> {
		const formData = new FormData()
		formData.append("file", file)

		const response = await xhrPostForm(`${restApiRootUrl}/participant/pension/external/`, formData)

		return this.convert(response)
	}
	async savePrivateBenefit(payload: IncomeItem): Promise<any> {
		try {
			return post(`${restApiRootUrl}/participant/pension/private/`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async deletePrivateBenefit(payload: IncomeItem): Promise<any> {
		try {
			return del(`${restApiRootUrl}/participant/pension/private/${payload.id}/`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async mutateRiskProfile(payload: any): Promise<any> {
		try {
			return post(`${mutationRootUrl}/riskprofile`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async mutateValueTransfer(payload: any): Promise<any> {
		try {
			return post(`${mutationRootUrl}/valuetransfer`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async mutateExtraSavings(payload: any): Promise<any> {
		try {
			return post(`${mutationRootUrl}/additionalsavings`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async mutateLifecycle(payload: any): Promise<any> {
		try {
			return post(`${mutationRootUrl}/lifecycle`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async getPensionPlan(payload: any): Promise<any> {
		try {
			return getJson(`${restApiRootUrl}/pensionplan/${payload.product}/${payload.pensionPlanID}`)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async getFund(payload: any, isRetired: boolean): Promise<any> {
		try {
			return getJson(`${restApiRootUrl}/fund/${payload.policyID}/${isRetired}`)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async getPensionCapitalAnnuity(payload: PensionEventPayload): Promise<any> {
		try {
			return post(`${restApiRootUrl}/ucm/getPensionCapitalAnnuity`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async getRiskProfileResult(payload: RiskProfileForCalculation): Promise<any> {
		const payloadString = {}
		for (const [key, value] of Object.entries(payload)) {
			if (value !== undefined) {
				Object.defineProperty(payloadString, key, {value: value.toString(), enumerable: true})
			}
		}
		try {
			return post(`${restApiRootUrl}/questionnaire/risk_profile`, payloadString)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
	async calculatePlannerResult(payload: PlannerChoicesPayload): Promise<PlannerResult | null> {
		try {
			return post(`${restApiRootUrl}/ucm/calculation`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
			return null
		}
	}

	async saveEmailAndCommunicationPreference(payload: CommunicationContact): Promise<any> {
		try {
			return post(`${restApiRootUrl}/participant/communicationContact`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}
}
