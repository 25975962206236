export interface Document {
	id: string
	identifier: string
	bucket: string
	type?: string
	published?: Date
	fileName: string
	mimeType: string
	size?: number
	created: Date
	modified: Date
	downloads: ReadonlyArray<Download>
}

export interface Download {
	at: Date
	by: string
}

export interface Dossier {
	documents: Array<Document>
	loaded: boolean
}

export const documentTypes = {
	CONFIRMATION_CHANGE: "documents.types.mutation",
	CONFIRMATION_PARTICIPATION: "documents.types.acknowledgement",
	CONFIRMATION_ENDING: "documents.types.mutation",
	CONFIRMATION_DIVORCE: "documents.types.mutation",
	POLICY: "documents.types.policy"
}

export const documentTitles = {
	CONFIRMATION_CHANGE: "documents.titles.mutatiebevestiging",
	CONFIRMATION_PARTICIPATION: "documents.titles.deelnamebevestiging",
	CONFIRMATION_ENDING: "documents.titles.beeindigingsbevestiging",
	CONFIRMATION_DIVORCE: "documents.titles.mutatiebevestigingscheiding",
	POLICY: "documents.titles.policy"
}
