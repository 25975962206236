import {Module} from "vuex"
import diff, {Diff} from "lib/date/diff"
import {RootState} from "../types"
import {Messages, Message} from "./types"
import {messageRootUrl} from "api/settings"
import {getJson} from "api/request"
import {convertMessages} from "./conversion"

const messageState: Messages = {
	messages: [],
	loaded: false
}

const dateDiffMillis = <T extends { date: Date }>(a: T, b: T) => diff(Diff.MILLIS, a.date, b.date)

const messages: Module<Messages, RootState> = {
	actions: {
		async load({commit, state}) {
			if (!state.loaded) {
				const response = await getJson(messageRootUrl)
				const result = convertMessages(response) || []

				commit("hydrate", result.sort((a, b) => b.date.getTime() - a.date.getTime()))
			}
		}
	},
	getters: {
		messages: state => [...state.messages].sort(dateDiffMillis),
		unreadMessages: state => state.messages.reduce((count, message) => count + (!message.read ? 1 : 0), 0)
	},
	mutations: {
		hydrate(state, payload: Array<Message>) {
			state.messages = payload
		},
		readMessage(state, message: Message) {
			if (state.messages.includes(message)) {
				message.read = true
			}
		}
	},
	namespaced: true,
	state: messageState
}

export default messages
