import {isString} from "lodash-es"

export const NAMESPACE = "@com.keylane.ics:"
export const MAP = "@map:"
export const SET = "@set:"

const replacer = (_key: string, value: any): any =>
	value instanceof Map ? `${MAP}${JSON.stringify([...value.entries()], replacer)}` :
	value instanceof Set ? `${SET}${JSON.stringify([...value.values()], replacer)}` :
	value

const reviver = (_key: string, value: any): any => {
	if (isString(value)) {
		return /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/.test(value) ? new Date(value) :
			value.startsWith(MAP) ? new Map(JSON.parse(value.substring(MAP.length), reviver)) :
			value.startsWith(SET) ? new Set(JSON.parse(value.substring(SET.length), reviver)) :
			value
	}
	return value
}

/**
 * The `StorageController` is a proxy that stores values in a `Storage` using JSON. It takes care to serialize
 * and deserialize `Date`s, `Map`s and `Set`s to and from JSON.
 *
 * Additionally, it reads all values from the `Storage` into a private store, so that values exist in the `Storage`
 * for a short duration only.
 */
export default class StorageController {
	private readonly values = new Map<string, any>()

	constructor(private readonly storage: Storage) {
		// Move the managed keys over to our private store.
		for (const [key, value] of Object.entries(storage)) {
			if (key.startsWith(NAMESPACE)) {
				this.values.set(key.substring(NAMESPACE.length), JSON.parse(value, reviver))
				storage.removeItem(key)
			}
		}

		if (!process.env.SERVER) {
			addEventListener("pagehide", () => {
				this.flush()
			})
		}
	}

	retrieve(key: string): any {
		return this.values.get(key)
	}

	store(key: string, value: any): void {
		this.values.set(key, value)
	}

	discard(key: string): void {
		this.values.delete(key)
	}

	flush(): void {
		for (const [key, item] of this.values) {
			this.storage.setItem(NAMESPACE + key, JSON.stringify(item, replacer))
		}
	}

}
