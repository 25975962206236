export interface HighlightData {
	code: string
	title: string
	url?: string
	event?: string
	highlightClass: string
	button?: string
}

export const highLightsCollection: Array<HighlightData> = [
	{
		code: "risicoprofiel_vastvariabel_A",
		title: "index.highlights.10",
		url: "riskprofile.html",
		highlightClass: "highlight__bg highlight_riskprofile",
		button: "index.highlights.buttonStart"
	},
	{
		code: "risicoprofiel_vastvariabel_B",
		title: "index.highlights.14",
		url: "riskprofile.html",
		highlightClass: "highlight__bg highlight_riskprofile",
		button: "index.highlights.buttonContinue"
	},
	{
		code: "risicoprofiel_vastvariabel_C",
		title: "index.highlights.18",
		url: "riskprofile.html",
		highlightClass: "highlight__bg highlight_riskprofile",
		button: "index.highlights.buttonStart"
	},
	{
		code: "risicoprofiel_vastvariabel_D",
		title: "index.highlights.20",
		url: "variablepension.html",
		highlightClass: "highlight__bg highlight_variablepension",
		button: "index.highlights.buttonStart"
	},
	{
		code: "risicoprofiel_vastvariabel_E",
		title: "index.highlights.24",
		url: "variablepension.html",
		highlightClass: "highlight__bg highlight_variablepension",
		button: "index.highlights.buttonContinue"
	},
	{
		code: "risicoprofiel_vastvariabel_F",
		title: "index.highlights.28",
		url: "variablepension.html",
		highlightClass: "highlight__bg highlight_variablepension",
		button: "index.highlights.buttonStart"
	},
	{
		code: "pensioendoel_overiginkomen_A",
		title: "index.highlights.30",
		url: "pensiongoal.html",
		highlightClass: "highlight__bg highlight_pensiongoal",
		button: "index.highlights.buttonStart"
	},
	{
		code: "pensioendoel_overiginkomen_B",
		title: "index.highlights.40",
		url: "pensiondetails.html",
		highlightClass: "highlight__bg highlight_pensiondetails",
		button: "index.highlights.buttonStart"
	},
	{
		code: "pensioendoel_overiginkomen_C",
		title: "index.highlights.45",
		url: "pensiondetails.html",
		highlightClass: "highlight__bg highlight_pensiondetails",
		button: "index.highlights.buttonContinue"
	},
	{
		code: "pensioendoel_overiginkomen_D",
		title: "index.highlights.50",
		url: "valuetransfer.html",
		highlightClass: "highlight__bg highlight_valuetransfer",
		button: "index.highlights.buttonStart"
	},
	{
		code: "pensioendoel_overiginkomen_E",
		title: "index.highlights.60",
		url: "pensiongoal.html",
		highlightClass: "highlight__bg highlight_pensiongoal",
		button: "index.highlights.buttonStart"
	},
	{
		code: "pensioendoel_overiginkomen_F",
		title: "index.highlights.65",
		url: "extrasavings.html",
		highlightClass: "highlight__bg highlight_extrasavings",
		button: "index.highlights.buttonStart"
	},
	{
		code: "pensioendoel_overiginkomen_G",
		title: "index.highlights.66",
		url: "extrasavings.html",
		highlightClass: "highlight__bg highlight_extrasavings",
		button: "index.highlights.buttonStart"
	},
	{
		code: "planner_A",
		title: "index.highlights.70",
		url: "planner.html",
		highlightClass: "highlight__bg highlight_planner",
		button: "index.highlights.buttonStart"
	},
	{
		code: "investments_A",
		title: "index.highlights.80",
		url: "myinvestments.html",
		highlightClass: "highlight__bg highlight_investments",
		button: "index.highlights.buttonStart"
	},
	{
		code: "overig_A",
		title: "index.highlights.90",
		url: "profile.html",
		highlightClass: "highlight__bg highlight_profile",
		button: "index.highlights.buttonStart"
	},
	{
		code: "overig_B",
		title: "index.highlights.100",
		url: "faq.html",
		highlightClass: "highlight__bg highlight_faq",
		button: "index.highlights.buttonStart"
	}
]
