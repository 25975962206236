var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.toasts),function(toast,index){return _c('div',{key:index,staticClass:"p-toast p-toast--is-active",class:{
      'p-toast--success': toast.type === 'SUCCESS',
      'p-toast--error': toast.type === 'ERROR',
      'p-toast--info': toast.type === 'INFO',
    }},[_c('p',{staticClass:"toast-text"},[_vm._v(_vm._s(_vm._f("locale")(toast.title)))]),_vm._v(" "),_c('button',{class:{
        'closebtn--success': toast.type === 'SUCCESS',
        'closebtn--error': toast.type === 'ERROR',
        'closebtn--info': toast.type === 'INFO',
      },on:{"click":function($event){return _vm.closeToast()}}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }