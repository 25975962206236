import {RootState} from "../types"
import {Riskprofile} from "./types"
import {Module} from "vuex"
import {PensionProfilePayload, initialPensionProfileState} from "store/state/types"
import store from ".."

const riskprofileState: Riskprofile = {
	profile: "",
	riskCharts: {
		["DEF"]: { title: "A", pessimistic: 0, expected: 0, optimistic: 0 },
		["NEU"]: { title: "B", pessimistic: 0, expected: 0, optimistic: 0 },
		["OFF"]: { title: "C", pessimistic: 0, expected: 0, optimistic: 0 }
	}
}

const hasEnddate = (endDate: any): boolean => endDate !== null && endDate.endDateTime !== undefined

const riskprofile: Module<Riskprofile, RootState> = {
	actions: {
		async profile(state) {

			const initalPensionProfileState: PensionProfilePayload = initialPensionProfileState
			const pensionProfileState = await store.dispatch("progressState/getProgressState", initalPensionProfileState)

			if (state.state.profile) {
				return state.state.profile
			} else if (hasEnddate(pensionProfileState.steps[3])) {
				const riskProfileCode = store.state.policyholder!.riskProfileCode

				const riskProfileCodeMapped = riskProfileCode!.includes("defensief")
					? "DEF"
					: riskProfileCode!!.includes("neutraal")
						? "NEU"
						: riskProfileCode!!.includes("offensief")
							? "OFF"
							: ""
				return riskProfileCodeMapped
			}
			return ""
		}
	},
	getters: {
		riskCharts: state => state.riskCharts,
		profileName: state => !state.profile ? "" : state.profile === "DEF" ? "defensief" : state.profile === "NEU" ? "neutraal" : "offensief"
	},
	mutations: {
		setProfile(state, profile: "DEF" | "NEU" | "OFF") {
			state.profile = profile
		}
	},
	namespaced: true,
	state: riskprofileState
}

export default riskprofile
