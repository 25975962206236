

























import Vue from "vue"
import Component from "vue-class-component"

import { IconHouse } from "custom/icons"
import IconLabel from "custom/IconLabel.vue"

import {
	SHOW_TOAST,
	Toast,
	eventBus,
	CONFIRM_TOAST,
	ConfirmationToast
} from "./toaster"

@Component({
	components: {
		IconHouse,
		IconLabel
	}
})
export default class PToaster extends Vue {
	toasts: Array<Toast> = []
	readonly baseTranslationKey = "toast"

	confirmToast: ConfirmationToast | null = null
	delay: number | undefined = undefined

	mounted(): void {
		eventBus.$on(SHOW_TOAST, async (toast: Toast) => {
			await this.show(toast)
		})
	}

	destroyed(): void {
		eventBus.$off(SHOW_TOAST)
	}

	handleResponse(confirmed: boolean): void {
		clearTimeout(this.delay)
		this.delay = undefined
		this.confirmToast = null
		eventBus.$emit(CONFIRM_TOAST, confirmed)
	}

	reject(): void {
		clearTimeout(this.delay)
		this.delay = undefined
		this.confirmToast = null
		eventBus.$emit(CONFIRM_TOAST, false)
	}

	private show(toast: Toast): Promise<void> {
		return new Promise<void>(resolve => {
			this.toasts.push(toast)

			setTimeout(() => {
				this.toasts.splice(this.toasts.indexOf(toast), 1)
				resolve()
			}, 5000)
		})
	}

	closeToast(): void {
		this.toasts = []
		eventBus.$off(SHOW_TOAST)
	}
}
