import {Income, Keyed, ChartView} from "components/chart/types"
import {PrivateBenefitType} from "lib/types/personal/participant"
import {ConversionFunction} from "lib/types/import"
import {privateBenefitType} from "lib/import/personalConvert"
import {or} from "lib/import/extract"
import {isUndefined} from "lodash-es"
import { FiscalType } from "lib/types/personal/policyholder"

export type IncomeType = "PENSION" | "SALARY" | "PARTTIME_PENSION" | "PARTTIME_SALARY" | "HIGH_LOW" | "BRIDGE" | "STATE_PENSION" | "NET"
export type PlannerIncomeType = IncomeType | PrivateBenefitType

export const incomeType: ConversionFunction<IncomeType> = (data: any) =>
	["PENSION", "SALARY", "PARTTIME_PENSION", "PARTTIME_SALARY", "HIGH_LOW", "BRIDGE", "STATE_PENSION", "NET"].includes(data) ? data : undefined
export const plannerIncomeType: ConversionFunction<PlannerIncomeType> = or(incomeType, privateBenefitType)
export const isPrivateBenefitType = (type: PlannerIncomeType): boolean => !isUndefined(privateBenefitType(type))
export const isNet = (fiscal: FiscalType): boolean => fiscal === "NET"
export const privateBenefitToOther = (type: PlannerIncomeType, fiscal: FiscalType): PlannerIncomeType => isPrivateBenefitType(type) ? (isNet(fiscal) ? "NET" : "OTHER") : type

export interface View {
	choices: Choices
	data: ReadonlyArray<Income<PlannerIncomeType> & Keyed>
	goal: number
	gross: boolean
	chart: Pick<ChartView<PlannerIncomeType>, "axis" | "range" | "lines" | "width" | "snap">
	focus: number,
	pensionExpectancy: PensionExpectancy
}

export type PensionExpectancy = "PESSIMISTIC" | "EXPECTED" | "OPTIMISTIC"

export interface Choices {
	work: {
		different: boolean
		age?: number
		workPercentage?: number
		fullHours?: number
		hours?: number
	},
	pension: {
		age: number
	},
	partner: {
		amount?: number
	},
	changing: {
		changingPension: boolean
		period?: string
	},
	extra: {
		extraPension: boolean
		amount?: number
		term?: Term
	}
}

export type Term = "ONCE" | "MONTHLY" | "YEARLY"

// for validations by the model
export interface ChoicesData {
	parttime: boolean
	workAgeYears?: number
	workAgeMonths?: number
	workHours?: number
	workHoursFull?: number
	pensionAgeYears?: number
	pensionAgeMonths?: number
	partnerAmount?: number
	extra: boolean
	extraAmount?: number
	extraTerm?: Term
}
