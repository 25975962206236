










import { Component, Prop, Vue } from "vue-property-decorator"
import Container from "components/generic/Container.vue"

@Component({
	components: {
		Container
	}
})
export default class List extends Vue {
	@Prop({ type: Array, required: false }) items!: Array<any>
	@Prop({ type: String, required: false}) childClass!: string
}
