import {Options} from "lib/types/i18n"

export default (locale: string): Promise<{default: Options}> => {
	switch (locale) {
		case "nl-NL": return import("i18n/nl-NL")
		case "en-UK": return import("i18n/en-UK")
	}

	return Promise.reject(new ReferenceError(`Unknown locale ${locale}`))
}
