import {Module} from "vuex"
import {RootState} from "../types"
import {Document, Dossier} from "./types"
import {convert as convertDocuments} from "./conversion"
import {restDocumentRootUrl} from "api/settings"
import {getJson, getBlob} from "api/request"

const dossierState: Dossier = {
	documents: [],
	loaded: false
}

export interface DownloadPayload {
	documentId: Document["id"]
}

const dossierModule: Module<Dossier, RootState> = {
	actions: {
		async load({commit, state}) {
			if (!state.loaded) {
				const response = await getJson(`${restDocumentRootUrl}`)
				const result = convertDocuments(response) || []

				commit("documents", result.sort((a, b) => b.created.getTime() - a.created.getTime()))
			}
		},
		downloadAttachment(_context, documentId: string): Promise<Blob> {
			return getBlob(`${restDocumentRootUrl}/stream/${documentId}`)
		}
	},
	getters: {
		unreadDocuments: state => state.documents.filter(document => document.downloads.length === 0).length
	},
	mutations: {
		documents(state, payload: Array<Document>) {
			state.documents = payload
		},
		addDownload(state: any, payload: DownloadPayload) {
			const newDownload = {
				at: new Date(),
				by: "customer"
			}
			state.documents.filter((document: Document) => document.id === payload.documentId)[0].downloads.push(newDownload)
		}
	},
	namespaced: true,
	state: dossierState
}

export default dossierModule
