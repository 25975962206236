import {getJson, put, get, patch} from "api/request"
import {restApiRootUrl, messageRootUrl} from "api/settings"

import {convert as convertPensiongoal} from "store/pensiongoal/conversion"
import {convertAllianzPolicies, convertAllianzRetireePolicies} from "store/pensiondetails/conversion"
import {pension as convertPension} from "store/participant/conversion"
import {one} from "lib/import/extract"
import formatISO from "lib/date/formatISO"
import {toaster} from "custom/toaster"

import {PensiongoalSave} from "store/pensiongoal/types"
import {AllianzPolicy, Age, AllianzRetireePolicy} from "store/pensiondetails/types"
import {Pension} from "store/participant/types"

export default class CustomController {

	async getPensiongoal(): Promise<any> {
		const response = await get(`${restApiRootUrl}/api/pensiongoal/`)
		if (response.status !== 200) {
			return []
		} else {
			const body = await response.json()
			return convertPensiongoal(body)
		}
	}

	async getPensiongoalAverages(gender: any, civilstatus: any, salary: any): Promise<any> {
		try {
			const response = await getJson(`${restApiRootUrl}/api/pensiongoal/average/${gender}/${civilstatus}/${salary}/TRUE`)
			return convertPensiongoal(response)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}

	async savePensiongoalAverages(payload: PensiongoalSave): Promise<any> {
		try {
			await put(`${restApiRootUrl}/api/pensiongoal/`, payload)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}

	async getPensiondetails(): Promise<Pension> {
		try {
			const response = await getJson(`${restApiRootUrl}/participant/pension/`)
			return one(convertPension)(response) || {}
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
			return {}
		}
	}

	async getAllianzPolicies(): Promise<Array<AllianzPolicy> | undefined> {
		try {
			const response = await getJson<Array<Array<AllianzPolicy>>>(`${restApiRootUrl}/policyholder/policies/`)
			return convertAllianzPolicies(response[0])
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
			return convertAllianzPolicies({})
		}
	}

	async getAllianzRetireePolicies(): Promise<any> {
		try {
			const response = await getJson<Array<Array<AllianzRetireePolicy>>>(`${restApiRootUrl}/policyholder/policies/retiree`)
			return convertAllianzRetireePolicies(response)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
			return convertAllianzPolicies({})
		}
	}

	async getStatePensionAge(date: Date): Promise<Age | null> {
		try {
			return getJson<Age>(`${restApiRootUrl}/calculation/state-pension-age/${formatISO(date)}`)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
			return null
		}
	}

	async getUnitPricesByDateRange(payload: any): Promise<any> {
		try {
			return getJson<any>(`${restApiRootUrl}/fund/currentFunds/${formatISO(payload.dateFrom)}/${formatISO(payload.dateTo)}`)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}

	async getEmailIntermediary(): Promise<any> {
		const response = await get(`${restApiRootUrl}/contract/intermediaryEmail`)
		if (response.status !== 200) {
			return ""
		} else {
			return response.text()
		}
	}

	async getEmailAdresses(): Promise<any> {
		try {
			return getJson(`${restApiRootUrl}/contract/emailAddresses`)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}

	async markMessageRead(messageId: any): Promise<any> {
		try {
			return patch(`${messageRootUrl}/markRead`, messageId)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}

}
