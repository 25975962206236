import {Gender, MaritalStatus, Status} from "lib/types/personal/personal"
import {Locale} from "lib/types/i18n"

export const gender = (locale: Locale) =>  (value: Gender) => {
	switch (value) {
		case "MALE": return locale.gender.male
		case "FEMALE": return locale.gender.female
		case "THIRD": return locale.gender.third
	}
	return ""
}

export const maritalStatus = (locale: Locale) => (value: MaritalStatus) => {
	switch (value) {
		case "Divorced": return locale.maritalStatus.divorced
		case "LegalCohabitation": return locale.maritalStatus.legalCohabitation
		case "LivingTogether": return locale.maritalStatus.livingTogether
		case "Married": return locale.maritalStatus.married
		case "Single": return locale.maritalStatus.single
		case "Unknown": return locale.maritalStatus.unknown
	}
	return ""
}

export const status = (locale: Locale) => (value: Status) => {
	switch (value) {
		case "Active": return locale.status.active
		case "Disabled": return locale.status.disabled
		case "Inactive": return locale.status.inactive
		case "Pensioner": return locale.status.pensioner
		case "Unknown": return locale.status.unknown
	}
	return ""
}
