import { getJson, get } from "api/request"
import { Maintenance, Policyholder } from "api/models/policyholder"
import { maintenanceRootUrl, restApiRootUrl } from "api/settings"

import { one, mandatory } from "lib/import/extract"
import { storageController } from "lib/storage/session"

import { convertMaintenance, policyholder as convertPolicyholder} from "store/policyholder/conversion"
import {toaster} from "custom/toaster"

export default class PolicyController {
	private readonly convert = mandatory(one(convertPolicyholder))

	async getOne(): Promise<Policyholder | undefined> {
		const response = await get(`${restApiRootUrl}/policyholder/profile/`)
		const NOT_ACCEPTABLE = 406

		if (response.status === NOT_ACCEPTABLE) {
			storageController.store("confidential", "SENSITIVE_CONTRACT")
			return undefined
		} else {
			return response.ok ? this.convert(await response.json()) : undefined
		}
	}

	async getAllianzPolicy(): Promise<any> {
		try {
			return getJson(`${restApiRootUrl}/policyholder/policies/`)
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
		}
	}

	async getMaintainanceObject(): Promise<Maintenance | null> {
		try {
			const response = await get(`${maintenanceRootUrl}`)
			return response.ok ? convertMaintenance(await response.json()) : null
		} catch {
			toaster.error({title: "general.httpErrorMessage", durationMs: 3000})
			return null
		}
	}
}
