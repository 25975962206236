import {Function0} from "lib/types/function"

interface ValueObject {
	valueOf: Function0<unknown>
}

/**
 * Groups the values in the iterable by the key returned from the function.
 *
 * Key equality is based on the value returned from `valueOf`. This enables grouping by some non-primitive types such as `Date`.
 */
export default <K extends ValueObject, V>(f: (value: V) => K, iterable: Iterable<V>): Map<K, ReadonlyArray<V>> => {
	const groups = new Map<K, Array<V>>()
	// Store keys in a map by the value returned from valueOf().
	const keys = new Map<unknown, K>()

	for (const value of iterable) {
		const key = f(value)
		const keyValue = key.valueOf()
		if (!keys.has(keyValue)) {
			keys.set(keyValue, key)
			groups.set(key, [])
		}
		// Make sure to access the map using the key stored in the keys map.
		groups.get(keys.get(keyValue)!)!.push(value)
	}

	return groups
}
