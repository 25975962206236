import {IdentificationStrategy, UsernamePassword} from "lib/types/security"
import { StorageOptions } from "lib/types/storage"
import StorageController from "lib/storage/StorageController"

const DEFAULT_KEY = "impersonateIdentification"

/**
 * Identification strategy that sends a POST request containing credentials for identification.
 */
export default class ImpersonateIdentification<C = UsernamePassword, P = any, T = any> implements IdentificationStrategy<C, T, P> {
	private readonly storage: StorageController
	private readonly key: string
	private _isIdentified: boolean = false
	private _identifier: string | undefined = undefined

	constructor(
		options: StorageOptions
	) {
		this.storage = options.storage
		this.key = options.key || DEFAULT_KEY
		this.identifier = this.storage.retrieve(this.key)
	}

	get isIdentified(): boolean {
		return this._isIdentified
	}

	async identify(): Promise<any> {
		if (this.isIdentified) {
			return
		}

		this._isIdentified = true
		return
	}

	async unidentify(): Promise<boolean> {
		if (this.isIdentified) {
				this._isIdentified = false
				return true
		}
		return false
	}

	get identifier(): string | undefined {
		return this._identifier
	}

	set identifier(identifier: string | undefined) {
		this._identifier = identifier
		this._isIdentified = true
		if (identifier) {
			this.storage.store(this.key, identifier)
		}
	}

}
