import Calculator from "./controllers/Calculator"
import Custom from "api/controllers/Custom"
import Participant from "api/controllers/Participant"
import Policyholder from "api/controllers/Policyholder"
import Root from "api/controllers/Root"
import State from "api/controllers/State"

export const calculator = new Calculator()
export const custom = new Custom()
export const participant = new Participant()
export const policyholder = new Policyholder()
export const root = new Root()
export const state = new State()
