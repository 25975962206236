import RequestStrategy from "lib/request/RequestStrategy"
import { isString, isArray, isNumber } from "lodash-es"

/**
 * Implements identification. In general, this involves calls to one or more security end points.
 */
export interface IdentificationStrategy<C, T = any, P = any> {
	/**
	 * Returns whether the user is identified successfully.
	 */
	readonly isIdentified: boolean

	/**
	 * Identifies the user using the `credentials`. When successful, the returned promise contains the token or session id (if any).
	 * If identification fails, a rejected promise is returned.
	 */
	identify(credentials: C): Promise<T>

	/**
	 * Removes the identification. Depending on implementation, this may involve a call to the server. The returned promise contains a
	 * boolean that determines whether the action succeeded.
	 */
	unidentify(payload?: P): Promise<boolean>

	/**
	 * Returns the user identifier. Implementations may choose to support this. When not supported, or absent, this returns `undefined`.
	 *
	 * This is a mutable property, so its value cannot be trusted completely. If the value is set, even if `undefined`, `isIdentified` returns `true`.
	 */
	identifier: string | undefined
}

/**
 * Implements authorization. Based on the result of a previous identification, the implementation grants access to a page or other resource.
 *
 * The `request` method, inherited from `RequestStrategy`, performs an authorized request.
 */
export interface AuthorizationStrategy extends RequestStrategy {
	/**
	 * Returns whether authorization is set. This means that access is granted if no roles are defined.
	 */
	readonly isAuthorized: boolean

	/**
	 * Sets the authorization used by this strategy. Returns whether the `authorization` is usable by the strategy.
	 */
	authorize(authorization: unknown): boolean

	/**
	 * Removes the authorization.
	 */
	unauthorize(): boolean

	/**
	 * Determines whether the user is authorized for all roles.
	 */
	isAuthorizedAll(roles: ReadonlyArray<string>): boolean

	/**
	 * Determines whether the user is authorized for at least one role.
	 */
	isAuthorizedAny(roles: ReadonlyArray<string>): boolean
}

export interface UsernamePassword {
	readonly username: string
	readonly password: string
}

export interface SamlArt {
	readonly samlArt: string
}

export interface SamlResponse {
	readonly samlResponse: string
}

export interface JwtToken {
	readonly username: string
	readonly roles: ReadonlyArray<string>
	readonly access_token: string
	readonly refresh_token: string
	readonly token_type: string
	readonly expires_in: number
}

export const isJwtToken = (data: any): data is JwtToken => data && isString(data.username) && isArray(data.roles) &&
	isString(data.access_token) && isString(data.refresh_token) && isString(data.token_type) && isNumber(data.expires_in)
