declare const config: {
	apiRootUrl: string
	authenticationImpersonateTokenUrl: string
	documentRootUrl: string
	messageRootUrl: string
	mutationRootUrl: string
	maintenanceRootUrl: string
	digid: IdentityProvider
	eidas: IdentityProvider
	sessionTimeout: number
	emailPopupIntervalDays: number
}

interface IdentityProvider {
	loginUrl: string,
	logoutUrl?: string,
	tokenUrl: string,
	verifyLogoutUrl?: string
}

if (process.env.SERVER) {
	(global as any).config = {
		apiRootUrl: "",
		authenticationImpersonateTokenUrl: "",
		documentRootUrl: "",
		messageRootUrl: "",
		mutationRootUrl: "",
		maintenanceRootUrl: "",
		digid: {
			logoutUrl: "",
			loginUrl: "",
			verifyLogoutUrl: "",
			tokenUrl: ""
		},
		eidas: {
			logoutUrl: "",
			loginUrl: "",
			verifyLogoutUrl: "",
			tokenUrl: ""
		},
		sessionTimeout: 0,
		emailPopupIntervalDays: 365
	}
}

export const restApiRootUrl = config.apiRootUrl
export const authenticationImpersonateTokenUrl = config.authenticationImpersonateTokenUrl
export const restDocumentRootUrl = config.documentRootUrl
export const messageRootUrl = config.messageRootUrl
export const mutationRootUrl = config.mutationRootUrl
export const maintenanceRootUrl = config.maintenanceRootUrl
export const digid = config.digid
export const eidas = config.eidas
export const sessionTimeout = config.sessionTimeout
export const emailPopupIntervalDays = config.emailPopupIntervalDays
