import {restApiRootUrl} from "api/settings"
import {getJson, post, del} from "api/request"
import {RiskProfilePayload, PensionProfilePayload, VariablePensionPayload, ValuetransferPayload, EmailAndCommunicationPreferencePayload} from "store/state/types"

const stateApiRootUrl = `${restApiRootUrl}/participant/state`

export default class StateController {
	/**
	 * Get the state of a customer journey
	 * param key key of state
	 */
	async getOne(key: string):
		Promise<RiskProfilePayload |
		VariablePensionPayload |
		PensionProfilePayload |
		EmailAndCommunicationPreferencePayload |
		ValuetransferPayload | unknown> {
		return await getJson(`${stateApiRootUrl}/${key}`)
	}

	/**
	 * Post the state of a customer journey
	 * param state the state of a customer journey
	 */
	async postOne(key: string, state: RiskProfilePayload | VariablePensionPayload | PensionProfilePayload |
		ValuetransferPayload | EmailAndCommunicationPreferencePayload): Promise<void> {
		return await post(`${stateApiRootUrl}/${key}`, state)
	}

	/**
	 * Delete the state of a customer journey
	 * param key key of state
	 */
	async DeleteOne(key: string): Promise<void> {
		return del(`${stateApiRootUrl}/${key}`)
	}
}
