import {Parameters} from "lib/types/request"

export const queryString = (parameters: Parameters) => {
	const encoded = encodeParameters(parameters)
	return encoded ? "?" + encoded : encoded
}

export const encodeParameters = (parameters: Parameters) => Object.keys(parameters)
	.filter(k => parameters[k] !== undefined)
	.map(k => `${encodeURIComponent(k)}=${encodeURIComponent(parameters[k])}`)
	.join("&")
