import {digid, eidas, sessionTimeout} from "./settings"
import {storageOptions} from "lib/storage/session"
import AuthorizedGateway from "lib/request/AuthorizedGateway"
import Session from "lib/security/Session"
import SamlIdentification from "lib/security/identification/SamlIdentification"
import ImpersonateIdentification from "lib/security/identification/ImpersonateIdentification"
import JwtAuthorization from "lib/security/authorization/JwtAuthorization"
import {Parameters, RequestMethod} from "lib/types/request"
import eventBus from "lib/vue/eventBus"
import {ACCESS_DENIED, ACCESS_REVOKED, AUTHORIZATION_FAILED, ACCESS_GRANTED, IDENTIFICATION_FAILED} from "lib/vue/events"
import store from "store/index"
import {JwtToken} from "lib/types/security"
import {storageController} from "lib/storage/session"
import StoredIdentification from "custom/utils/StoredIdentification"
import { AuthenticationMethod, authenticationStorage } from "custom/utils/AuthenticationStorage"

import { authenticationImpersonateTokenUrl } from "api/settings"

import { isString } from "lodash-es"

const authorization = new JwtAuthorization(storageOptions)
const gateway = new AuthorizedGateway(authorization)

const digidIdentification = new SamlIdentification(
	{ ...storageOptions, key: "digid" },
	gateway,
	digid.tokenUrl,
	digid.logoutUrl,
	digid.verifyLogoutUrl
)
const eIdasIdentification = new SamlIdentification(
	{ ...storageOptions, key: "eidas" },
	gateway,
	eidas.tokenUrl,
	eidas.logoutUrl,
	eidas.verifyLogoutUrl
)

const impersonateIdentification = new ImpersonateIdentification(
	{ ...storageOptions, key: "impersonate" }
)

const identificationMethods = {
	[AuthenticationMethod.DIGID]: digidIdentification,
	[AuthenticationMethod.EIDAS]: eIdasIdentification,
	[AuthenticationMethod.IMPERSONATE]: impersonateIdentification
}

const identification = new StoredIdentification(identificationMethods, authenticationStorage)
export const session = new Session(identification, authorization, sessionTimeout)

export const LOGIN_FAILED = "login-failed"

if (!process.env.SERVER) {
	// N.B.: ACCESS_DENIED is not handled. This occurs when a Client Control user tries to perform an action that is not allowed.
	// 'confidential' is handled in case of an impersonate request for a person that belongs to a 'sensitve contract'
	eventBus.on("confidential", async () => {
		location.href = "./sensitivecontract.html"
	})
	eventBus.on(IDENTIFICATION_FAILED, async (reason: any) => {
	// Store the reason (if given) for use by the login page.
		if (reason) {
			storageController.store(LOGIN_FAILED, reason)
		}
		// Do not logout completely. This way, the token remains valid for some calls,
		// while the session itself is not valid.
		await session.forget()
		store.commit("logout")
		authenticationStorage.authenticationMethod = undefined
		location.href = "./login.html"
	})
	eventBus.on(ACCESS_REVOKED, async () => {
		store.commit("logout")
		authenticationStorage.authenticationMethod = undefined
		location.href = "./login.html"
	})
	eventBus.on(AUTHORIZATION_FAILED, async () => {
		await session.login(null)
		authenticationStorage.authenticationMethod = undefined
	})
	eventBus.on("impersonate", async (token: JwtToken) => {
		if (session.impersonate(token.username, token)) {
			authenticationStorage.authenticationMethod = AuthenticationMethod.IMPERSONATE
			eventBus.emit(ACCESS_GRANTED, token.username)
		} else {
			eventBus.emit(ACCESS_DENIED)
		}
	})
	eventBus.on("impersonate-by-token", async (token: JwtToken | string) => {
		// eslint-disable-next-line init-declarations
		let jwtToken: JwtToken
		if (isString(token)) {
			const externalToken = {
				username: "",
				roles: [],
				access_token: token,
				refresh_token: "",
				token_type: "Bearer",
				expires_in: 0
			}
			authorization.authorize(externalToken)
			jwtToken = await post(authenticationImpersonateTokenUrl ?? "")
			authorization.unauthorize()
		} else {
			jwtToken = token
		}
		eventBus.emit("impersonate", jwtToken)
	})
}

// tslint:disable-next-line: no-empty
const noop = () => {}

export const get = (input: string, data?: Parameters): Promise<Response> => gateway.request("GET", input, data)
export const postResponse = (input: string, data?: Parameters): Promise<Response> => gateway.request("POST", input, data)

export const getJson = async <T>(input: string, data?: Parameters): Promise<T> => (await get(input, data)).json()

export const getBlob = async (input: string, data?: Parameters): Promise<Blob> => (await get(input, data)).blob()

// Not all POSTs return json, so catch.
const request = async <T>(method: RequestMethod, input: string, data?: Parameters): Promise<T> =>
	(await gateway.request(method, input, data)).json().catch(noop)

export const post = async <T>(input: string, data?: Parameters): Promise<T> => request("POST", input, data)
export const put = async <T>(input: string, data?: Parameters): Promise<T> => request("PUT", input, data)
export const del = async <T>(input: string, data?: Parameters): Promise<T> => request("DELETE", input, data)
export const patch = async <T>(input: string, data?: Parameters): Promise<T> => request("PATCH", input, data)
