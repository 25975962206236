import {ConversionMap} from "lib/types/import"
import {Document, Download} from "./types"
import {alphanumeric, date, datetime, numeric} from "lib/import/convert"
import {many, def} from "lib/import/extract"

const required = true
const optional = false

const download: ConversionMap<Download> = {
	at: ["at", datetime, required],
	by: ["by", alphanumeric, required]
}

const document: ConversionMap<Document> = {
	id: ["id", alphanumeric, required],
	identifier: ["identifier", alphanumeric, required],
	bucket: ["bucket", alphanumeric, required],
	type: ["type", alphanumeric, optional],
	published: ["published", date, optional],
	fileName: ["fileName", alphanumeric, required],
	mimeType: ["mimeType", alphanumeric, required],
	size: ["size", numeric, optional],
	created: ["created", date, required],
	modified: ["modified", date, required],
	downloads: ["downloads", def([], many(download)), required]
}

export const convert = many(document)
