














import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class Message extends Vue {

	hasDate = false
	hasIcon = false

	mounted() {
		this.hasDate = !!this.$slots.date
		this.hasIcon = !!this.$slots.icon
	}

	clicked(e: MouseEvent) {
		this.$emit("click", e)
	}
}
