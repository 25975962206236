import Vue from "vue"
import {cloneDeep, get, merge, set} from "lodash-es"
import {Plugin, Store} from "vuex"
import {StoreStorageOptions} from "lib/types/vuex"
import eventBus from "lib/vue/eventBus"

const DEFAULT_KEY = "store"

export const STORE_HYDRATED = "store-hydrated"

export const save = (options: StoreStorageOptions, store: Store<any>): void => {
	const key = options.key || DEFAULT_KEY
	if (options.deactivate && options.deactivate(store)) {
		options.storage.discard(key)
		return
	}
	if (options.empty && options.empty(store)) {
		return
	}

	const whitelist = options.whitelist || []
	const blacklist = options.blacklist || []

	const persistState = whitelist.length
		? whitelist.reduce(
				(state, path) => set(state, path, get(store.state, path)),
				{}
			)
		: blacklist.length
			? blacklist.reduce(
					(state, path) => set(state, path, undefined),
					cloneDeep(store.state)
				)
			: store.state

	options.storage.store(key, persistState)
}

/**
 * Creates the plugin function that stores Vuex state as defined by the `StoreStorageOptions`.
 *
 * @returns {Plugin} the plugin function.
 *
 * The plugin function must be passed on to the Vuex store on initialization.
 * The save function can be used to trigger saves in storage. Explicit triggering should not
 * be necessary, but can come in handy (testing is an example).
 *
 * The plugin function uses the save function internally.
 */
export default (options: StoreStorageOptions): Plugin<any> => {
	const key = options.key || DEFAULT_KEY

	return (store: Store<any>) => {
		const state = options.storage.retrieve(key)
		Vue.nextTick(() => {
			// We have to postpone replacing the state, because Vue hydration might otherwise fail.
			// The initial rendering of the page must remain as close as possible to the SSR rendered page. If the store
			// is populated before the initial rendering, there could be differences between the page and the SSR version
			// that Vue does not expect. In some cases this leads to client side errors.
			store.replaceState(merge(cloneDeep(store.state), state))
			eventBus.emit(STORE_HYDRATED, store)
		})

		store.subscribe(() => save(options, store))
	}
}
