import {ConversionMap, ConversionFunction} from "lib/types/import"
import {one, many, mandatory, oneOf} from "lib/import/extract"
import {
	numeric,
	date,
	alphanumeric,
	bool
} from "lib/import/convert"
import {
	fiscalType,
	beneficiary,
	trigger,
	civilStatusType,
	gender,
	civilStatusReason,
	employmentStatus,
	policyStatus,
	accrualCoverType,
	accrualCoverCause,
	insuranceCoverCause,
	insuranceCoverType,
	reserveCoverCause
} from "lib/import/personalConvert"
import {
	AccrualCover,
	Address,
	Addresses,
	Cover,
	CivilStatus,
	Contact,
	Covers,
	Employer,
	Employment,
	Financial,
	InsuranceCover,
	Partner,
	Person,
	Personal,
	Policy,
	Policyholder,
	Premium,
	Scheme,
	ReserveCover,
	Equalization,
	MaintenanceMessage,
	Maintenance
} from "./types"

const arrayOrEmpty = <T>(conversion: ConversionMap<T>): ConversionFunction<Array<T>> => (v: any) => many(conversion)(v) || []

const required = true
const optional = false

const communication = (value: any): "ONLINE" | "OFFLINE" => ["ONLINE", "OFFLINE"].includes(value) ? value : "ONLINE"
const inPondresFile = (value: any): boolean => ["ONLINE", "OFFLINE"].includes(value)
// Person
export const personal: ConversionMap<Personal> = {
	birthdate: ["birthdate", date, required],
	firstName: ["firstName", alphanumeric, optional],
	gender: ["gender", gender, required],
	initials: ["initials", alphanumeric, optional],
	lastName: ["lastName", alphanumeric, required],
	prefix: ["prefix", alphanumeric, optional]
}

export const financial: ConversionMap<Financial> = {
	bic: ["bic", alphanumeric, optional],
	iban: ["iban", alphanumeric, required]
}

export const address: ConversionMap<Address> = {
	city: ["city", alphanumeric, optional],
	suffix: ["suffix", alphanumeric, optional],
	street: ["street", alphanumeric, optional],
	regionCode: ["regionCode", alphanumeric, optional],
	postalCode: ["postalCode", alphanumeric, optional],
	number: ["number", alphanumeric, optional],
	countryCode: ["countryCode", alphanumeric, optional]
}

export const addresses: ConversionMap<Addresses> = {
	home: ["home", one(address), optional],
	postal: ["postal", one(address), required]
}

export const contact: ConversionMap<Contact> = {
	address: ["address", one(addresses), optional],
	telephone: ["telephone", alphanumeric, optional],
	email: ["email", alphanumeric, optional]
}

export const person: ConversionMap<Person> = {
	identificationNumber: ["identificationNumber", alphanumeric, optional],
	relationNumber: ["relationNumber", alphanumeric, required],
	externalId: ["externalId", alphanumeric, optional],
	personal: ["personal", one(personal), required],
	financial: ["financial", one(financial), optional]
}

// Partner
export const civilStatus: ConversionMap<CivilStatus> = {
	endDate: ["endDate", date, optional],
	type: ["type", civilStatusType, required],
	startDate: ["startDate", date, optional],
	reason: ["reason", civilStatusReason, optional]
}

export const partner: ConversionMap<Partner> = {
	...person,
	civilStatus: ["civilStatus", one(civilStatus), required]
}

// Employment

export const employer: ConversionMap<Employer> = {
	id: ["id", alphanumeric, required],
	name: ["name", alphanumeric, required]
}

export const employment: ConversionMap<Employment> = {
	disabilityPercentage: ["disabilityPercentage", numeric, required],
	startDate: ["startDate", date, required],
	endDate: ["endDate", date, optional],
	id: ["id", alphanumeric, required],
	employer: ["employer", one(employer), required],
	fulltimeSalary: ["fulltimeSalary", numeric, required],
	parttimePercentage: ["parttimePercentage", numeric, required],
	status: ["status", employmentStatus, required]
}

// Policy

export const premium: ConversionMap<Premium> = {
	employee: ["employee", numeric, required],
	employer: ["employer", numeric, required],
	private: ["private", numeric, required],
	voluntary: ["voluntary", numeric, required]
}

export const equalization: ConversionMap<Equalization> = {
	amount: ["amount", numeric, optional],
	status: ["status", oneOf("UNKNOWN", "DEDUCTED", "SEPARATED"), required]
}

export const cover: ConversionMap<Cover> = {
	beneficiary: ["beneficiary", beneficiary, required],
	title: ["title", alphanumeric, optional],
	id: ["id", alphanumeric, required],
	endDate: ["endDate", date, optional],
	startDate: ["startDate", date, optional],
	currency: ["currency", alphanumeric, required],
	referenceDate: ["referenceDate", date, required],
	equalization: ["equalization", one(equalization), optional],
	trigger: ["trigger", trigger, required]
}

export const accrualCover: ConversionMap<AccrualCover> = {
	...cover,
	accrued: ["accrued", numeric, required],
	benefit: ["benefit", numeric, required],
	cause: ["cause", accrualCoverCause, required],
	type: ["type", accrualCoverType, required]
}

export const insuranceCover: ConversionMap<InsuranceCover> = {
	...cover,
	benefit: ["benefit", numeric, required],
	cause: ["cause", insuranceCoverCause, required],
	type: ["type", insuranceCoverType, required]
}

export const reserveCover: ConversionMap<ReserveCover> = {
	...cover,
	fiscal: ["fiscal", fiscalType, required],
	cause: ["cause", reserveCoverCause, required],
	reserve: ["reserve", numeric, required]
}

export const covers: ConversionMap<Covers> = {
	accrual: ["accrual", many(accrualCover), required],
	insurance: ["insurance", many(insuranceCover), required],
	reserve: ["reserve", many(reserveCover), required]
}

export const scheme: ConversionMap<Scheme> = {
	covers: ["covers", one(covers), required],
	id: ["id", alphanumeric, required],
	name: ["name", alphanumeric, required]
}

export const policy: ConversionMap<Policy> = {
	activeDate: ["activeDate", date, optional],
	id: ["id", alphanumeric, required],
	status: ["status", policyStatus, required],
	createdDate: ["createdDate", date, required],
	employmentId: ["employmentId", alphanumeric, optional],
	inactiveDate: ["inactiveDate", date, optional],
	payingDate: ["payingDate", date, optional],
	premium: ["premium", one(premium), optional],
	schemes: ["schemes", many(scheme), required]
}

// Policyholder
export const policyholder: ConversionMap<Policyholder> = {
	...person,
	id: ["id", alphanumeric, required],
	civilStatus: ["civilStatus", civilStatusType, required],
	divorcedPast: ["divorcedPast", bool, optional],
	widowedPast: ["widowedPast", bool, optional],
	partners: ["partners", arrayOrEmpty(partner), required],
	employments: ["employments", arrayOrEmpty(employment), required],
	policies: ["policies", arrayOrEmpty(policy), required],
	policyKeys: ["policyKeys", many(String), optional],
	netYearlySalary: ["nonexistent", () => 0, required],
	grossYearlySalary: ["nonexistent", () => 0, required],
	riskProfileCode: ["riskProfileCode", alphanumeric, optional],
	communication: ["communication.legal", communication, required],
	contact: ["contact", one(contact), required],
	fiscalRoomYearly: ["nonexistent", () => 0, required],
	retired: ["retired", bool, required],
	fiscalRoomOneTime: ["nonexistent", () => 0, required],
	inPondresFile: ["communication.legal", inPondresFile, required]
}

export const maintenanceMessage: ConversionMap<MaintenanceMessage> = {
	locale: ["locale", alphanumeric, required],
	title: ["title", alphanumeric, required],
	message: ["message", alphanumeric, required]
}

export const maintenance: ConversionMap<Maintenance> = {
	id: ["id", alphanumeric, optional],
	portal: ["portal", alphanumeric, optional],
	messages: ["messages", arrayOrEmpty(maintenanceMessage), required]
}

export const convertMaintenance = mandatory(one(maintenance))

export const convert = mandatory(one(policyholder))
