import {FiscalType} from "lib/types/personal/policyholder"
import {PlannerIncomeType} from "custom/planner/types"
import {RiskProfileCharts, Profiles} from "store/riskprofile/types"

export interface Pensiondetails {
	incomeItems: Array<IncomeItem>
	allianzPensionPolicies: Array<AllianzPolicy>
	allianzRetireePolicies: Array<AllianzRetireePolicy>
	allianzPensionPlans: Array<AllianzPensionPlan>
	aow: AOW
	statePensionAge: Age
	fundsLoaded: boolean
	zeroAlterationAmountsLoaded: boolean
	endparticipationAmountsLoaded: boolean
	riskChartAmountsLoaded: boolean
	riskProfileCharts: RiskProfileCharts
	riskProfileForVariableCharts: RiskProfileCharts
	policyRiskProfileChartLoaded: Array<PolicyRiskProfileCombination>
	policyVariableChartLoaded: Array<PolicyRiskProfileCombination>
	isEdited: boolean
}

export interface PolicyRiskProfileCombination {
	policyId: string
	riskProfileShortCode: Profiles
}

export interface AOW {
	title: "AOW"
	id?: string
	civilStatusNpr?: string
	typeOfAOW?: "SINGLE" | "PARTNER"
	single: number
	partner: number
	removable?: boolean
	editable?: boolean
	startDate: Date
	referenceDate: Date
}

export interface Saving {
	currentWorth: number
	increment: number
	period: "year" | "quarter" | "month"
}

export interface IncomeItem {
	name: string
	reference?: string
	id?: string
	type: PlannerIncomeType
	benefit: number
	startDate: Date | null
	endDate?: Date | null
	fiscal: FiscalType
	referenceDate: Date
	removable?: boolean
	editable?: boolean
	indicative?: boolean
}

export interface TransactionObject {
	date: Date
	transactionItem: TransactionItem
	type: string
}
export interface TransactionItem {
	fundCode: string
	fundName: string
	rate: number
	units: number
	value: number
}

export interface TransactionTypeGroup {
	[key: string]: Array<TransactionObject>
}

export interface Fund {
	key: string
	value: number
	units: number
}

export interface UnitPriceObject {
	date: Date
	unitPrice: number
}

export interface FundUnitPriceObject {
	key: string
	unitPrices: Array<UnitPriceObject>
}

export interface AllianzPolicy {
	id: string
	name: string
	grossAnnualSalary: number
	pensionableAnnualSalary: number
	pensionablePay: number
	franchise: number
	insurancesBalance: number
	currentFunds: Array<Fund>
	currentPremium: number
	currentPremiumTerm?: string
	scalePercentage: number
	participationWeight: number
	status?: string
	pensionPlanID: string
	pp_Verzekerd: number
	wzp_Verzekerd: number
	anw: number
	waoHiaat: number
	waoExcedent: number
	product: string
	standDatum: Date
	pensioenDatum: Date
	annual: number
	expectedCapital: number
	riskProfileId: string
	riskProfileName: string
	riskProfileOverride: string
	transactions: Map<number, TransactionTypeGroup>
	zeroAlterationAmounts: AmountGroup
	endOfParticipationAmounts: AmountGroup
	pensionPlanInfo: AllianzPensionPlan
	fiscalRoomYearly: number
	fiscalRoomOneTime: number
	hasOldAgePension: boolean
	alterationDate: Date
	startDate: Date
	alterationDescription: string
	employmentId: string
	voluntaryPartnerPension: number
}

export interface AllianzRetireePolicy {
	grossPerYear: number
	benefitAmount: number
	annuity: number
	id: string
	policySequenceNr: string
	type: BenefitType
	paymentFrequency: PaymentFrequency
	partner?: PartnerDeathCoverage
	benefitEndDate?: Date
	insurancesBalance: number
	highLow?: RetireeHighLow
	currentFunds: Array<Fund>
	transactions: Map<number, TransactionTypeGroup>
}

export enum PaymentFrequency {
	YEARLY = "YEARLY",
	HALF_YEARLY = "HALF_YEARLY",
	TRIMESTER = "TRIMESTER",
	QUARTERLY = "QUARTERLY",
	TWO_MONTHLY = "TWO_MONTHLY",
	MONTHLY = "MONTHLY"
}

export enum BenefitType {
	DIP_VAST = "DIP_VAST",
	DIP_VARIABLE = "DIP_VARIABLE",
	DIL = "DIL",
	DIL_VARIABLE = "DIL_VARIABLE"
}

export interface PartnerDeathCoverage {
	percentage: number
	amount: number
}

export interface RetireeHighLow {
	highFactor: number
	endDateHigh: Date
}

export interface RiskProfileCode {
	key: string
	name?: string
}
export interface AllianzPensionPlan {
	availableRiskProfiles?: Array<RiskProfileCode>
	hasMin10RiskProfiles: boolean
	pensioenklik_Regeling: boolean
	bijsparen_Regeling: boolean
	startDate: Date
}

export interface RiskProfileNamePayload {
	policyId: string
	riskProfileName: string
}

export interface AmountGroup {
	pessimisticCapital: number
	expectedCapital: number
	optimisticCapital: number
	pessimisticAnnuity: number
	expectedAnnuity: number
	optimisticAnnuity: number
	pessimisticRealAnnuity: number
	expectedRealAnnuity: number
	optimisticRealAnnuity: number
}

export type PensionPayloadEvent = "PensionEventZeroAlteration" | "PensionEventEndParticipation" | "PensionEventSimulation"

export interface PensionEventPayload {
	participationKey: number
	pensionEvent: PensionPayloadEvent
	ucmCalculation: boolean
	alterationDate: string
	pensionDate: string
	riskProfileKey?: string
}

export interface RiskProfileToCalculate {
	policyId: string,
	pensionDate: string,
	riskProfileKey: string
}

export interface ValueTransferPension extends IncomeItem {
	indicative: boolean
	goal: number // te bereiken
	current: number // opgebouwd
	transfer: boolean | null
}

export interface PolicyTransactionInfo {
	policyId: string,
	transactions: Map<number, TransactionTypeGroup>,
	policySum: number
}

export interface CapitalInfo {
	policyId: string,
	capitals: AmountGroup
}

export type RiskProfileShortCodes = "DEF" | "NEU" | "OFF"
export const riskProfileShortCodes = ["DEF", "NEU", "OFF"]

export interface RiskProfileCodeSet {
	currentRiskProfileShortCode: RiskProfileShortCodes | null,
	remainingRiskProfileShortCodes: Array<RiskProfileShortCodes>
}

export interface HistoricalRiskProfileCodeSet extends RiskProfileCodeSet {
	currentLifeCycle: HistoricalLifecycle | undefined
}

export interface PlannerChoicesPayload {
	calculationDate: string
	dateOfBirth: string
	salary: number
	capitalForeCast: number
	forecast: number
	accrued: number
	currentPartTimePercentage: number
	predefinedPensionAge: number
	activePartTime: boolean
	partTimePercentage?: number
	partTimeAge?: number
	activePensionAge: boolean
	pensionAge?: number
	activeSavings: boolean
	scenario: "PESSIMISTIC" | "EXPECTED" | "OPTIMISTIC"
	frequency?: "YEARLY" | "MONTHLY" | "ONCE"
	deposit?: number
}

export interface PlannerResult {
	parttime: PeriodAmount
	pension: PeriodAmount
	salary: PeriodAmount
}

export interface PeriodAmount {
	amount: number
	from: number
	to: number
}

export interface Age {
	years: number
	months: number
}

export interface WhatIfTotals {
	pp_Verzekerd: number
	wzp_Verzekerd: number
	anw: number
	waoHiaat: number
	waoExcedent: number
}

export interface HistoricalLifecycleSet {
	name: string
	code: RiskProfileShortCodes
}

export interface HistoricalLifecycle {
	product: string
	group: string,
	lifecycles: Array<HistoricalLifecycleSet>
}
