import {ConversionMap} from "lib/types/import"
import {many} from "lib/import/extract"
import {alphanumeric, date, bool} from "lib/import/convert"
import {Message} from "./types"

const convertNewLines = (text: string): string => text.replace(/(?:\r\n|\r|\n)/g, "<br>")

const required = true
// const optional = false

const message: ConversionMap<Message> = {
	id: ["id", alphanumeric, required],
	messageId: ["messageId", alphanumeric, required],
	title: ["title", alphanumeric, required],
	body: ["body", convertNewLines, required],
	date: ["timestamp", date, required],
	read: ["read", bool, required]
}

export const convertMessages = many(message)
