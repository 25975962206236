import {Module} from "vuex"
import {RootState} from "../types"
import {StateModule,
		RiskProfilePayload,
		PensionProfilePayload,
		initialPensionProfileState,
		ValuetransferPayload,
		initialValueTransferState,
		EmailAndCommunicationPreferencePayload,
		VariablePensionPayload} from "./types"
import {HighlightData, highLightsCollection} from "./highlights"
import {ruleCategories,
		inArray,
		trueFalse,
		undefinedOrNull,
		notUndefinedOrNull,
		Rule,
		diffDateSmallerThen,
		diffDateGreaterThen,
		greaterThen,
		smallerThen} from "./rules"
import {state as stateApi} from "api/gateway"
import {convertRiskProfilePayload, convertPensionProfilePayload,
	convertVariablePensionPayload, convertValuetransferPayload, convertEmailAndCommunicationPreferencePayload} from "./conversion"
import store from "../index"

const stateModuleState: StateModule = {}

const stateModule: Module<StateModule, RootState> = {
	state: stateModuleState,
	mutations: {},
	actions: {
		async updateProgressState(_context, payload: RiskProfilePayload | VariablePensionPayload | PensionProfilePayload | ValuetransferPayload) {
			await stateApi.postOne(payload.key!, payload)
		},
		async getProgressState(_context, payload: RiskProfilePayload |
			VariablePensionPayload | PensionProfilePayload | ValuetransferPayload | EmailAndCommunicationPreferencePayload) {
			let journeyState!: RiskProfilePayload | VariablePensionPayload |
				PensionProfilePayload | ValuetransferPayload | EmailAndCommunicationPreferencePayload
			const response = await stateApi.getOne(payload.key!)

			if (typeof response === "object") {
				const messageKey = "message"
				if (response && (messageKey in response)) {
					await stateApi.postOne(payload.key!, payload)
					journeyState = payload
				} else {

					if (payload.key === "CJ_RISKPROFILE") {
						journeyState = response as RiskProfilePayload
						journeyState.key = payload.key
						return convertRiskProfilePayload(journeyState)
					}

					if (payload.key === "CJ_VARIABLEPENSION") {
						journeyState = response as VariablePensionPayload
						journeyState.key = payload.key
						return convertVariablePensionPayload(journeyState)
					}

					if (payload.key === "PENSION_PROFILE") {
						journeyState = response as PensionProfilePayload
						journeyState.key = payload.key
						return convertPensionProfilePayload(journeyState)
					}

					if (payload.key === "CJ_VALUETRANSFER") {
						journeyState = response as ValuetransferPayload
						journeyState.key = payload.key
						return convertValuetransferPayload(journeyState)
					}

					if (payload.key === "CJ_EMAILANDCOMMUNICATIONPREFERENCES") {
						journeyState = response as EmailAndCommunicationPreferencePayload
						journeyState.key = payload.key
						return convertEmailAndCommunicationPreferencePayload(journeyState)
					}
				}
			}

			return journeyState
		},
		async highLights(context): Promise<Array<HighlightData>> {

			const pensionProfileState: PensionProfilePayload = await store.dispatch("progressState/getProgressState", initialPensionProfileState)
			const boolMin10RiskProfiles: boolean = context.rootGetters["pensiondetails/policiesWithMoreThan10RProfiles"].length > 0
			const policyHolderMainStatus = context.rootGetters["pensiondetails/policyHolderMainStatus"]
			const boolPolicyWithOwnInvestmentStrategy = context.rootGetters["pensiondetails/policiesWithOwnInvestmentStrategy"]
			const age: number = context.rootGetters["policyholder/age"]
			const totalMonthlyNet: number = await store.dispatch("pensiondetails/totalMonthlyNet")
			const valuetransferState: ValuetransferPayload = await store.dispatch("progressState/getProgressState", initialValueTransferState)
			const currentEmployments = context.rootGetters["policyholder/currentEmployments"]
			const boolExtraSavingsIsAvailable: boolean = context.rootGetters["pensiondetails/isExtraSavingsAvailable"]
			const boolSleeper: boolean = policyHolderMainStatus === "Sleeper"
			const boolHasOldAgePensionAvailable: boolean = context.rootGetters["pensiondetails/hasOldAgePension"]
			const boolIsPensioner: boolean = context.rootGetters["policyholder/isRetired"]
			const boolHasRetireeVariablePolicy: boolean = context.rootGetters["pensiondetails/hasRetireeVariablePolicy"]

			const highLigths: Array<HighlightData> = []

			const alwaysTrue = {
				rulefunction: trueFalse,
				ruleparam: true,
				rulevalue: true
			}

			const activeSleeper = {
				rulefunction: inArray,
				ruleparam: ["Active", "Sleeper"],
				rulevalue: policyHolderMainStatus
			}

			const active = {
				rulefunction: inArray,
				ruleparam: ["Active"],
				rulevalue: policyHolderMainStatus
			}

			const notRetiree = {
				rulefunction: trueFalse,
				ruleparam: false,
				rulevalue: boolIsPensioner
			}

			const hasRetireeVariablePolicy = {
				rulefunction: trueFalse,
				ruleparam: true,
				rulevalue: boolHasRetireeVariablePolicy
			}

			const notSleeper = {
				rulefunction: trueFalse,
				ruleparam: false,
				rulevalue: boolSleeper
			}

			const hasMin10RiskProfiles = {
				rulefunction: trueFalse,
				ruleparam: true,
				rulevalue: boolMin10RiskProfiles
			}

			const hasNoPolicyWithOwnInvestmentStrategy = {
				rulefunction: trueFalse,
				ruleparam: false,
				rulevalue: boolPolicyWithOwnInvestmentStrategy
			}

			const riskProfileCJNotStarted = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[1].startDateTime
			}

			const riskProfileCJStarted = {
				rulefunction: notUndefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[1].startDateTime
			}

			const riskProfileCJNotEnded = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[3].endDateTime
			}

			const riskProfileCJEnded = {
				rulefunction: notUndefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[3].endDateTime
			}

			const riskProfileCJMoreThenYearAgo = {
				rulefunction: diffDateGreaterThen,
				ruleparam: 12,
				rulevalue: pensionProfileState.steps[3].endDateTime
			}

			const riskProfileCJLessThenYearAgo = {
				rulefunction: diffDateSmallerThen,
				ruleparam: 12,
				rulevalue: pensionProfileState.steps[3].endDateTime
			}

			const vastVariabelCJNotStarted = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[4].startDateTime
			}

			const vastVariabelCJStarted = {
				rulefunction: notUndefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[4].startDateTime
			}

			const vastVariabelCJNotEnded = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[4].endDateTime
			}

			const age42OrBelow = {
				rulefunction: smallerThen,
				ruleparam: 42,
				rulevalue: age
			}

			const age43OrAbove = {
				rulefunction: greaterThen,
				ruleparam: 43,
				rulevalue: age
			}

			const pensionGoalNotEnded = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[5].endDateTime
			}

			const pensionGoalEnded = {
				rulefunction: notUndefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[5].endDateTime
			}

			const nprNotEnded = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[6].endDateTime
			}

			const nprEnded = {
				rulefunction: notUndefinedOrNull,
				ruleparam: undefined,
				rulevalue: pensionProfileState.steps[6].endDateTime
			}

			const nprMoreThenYearAgo = {
				rulefunction: diffDateGreaterThen,
				ruleparam: 12,
				rulevalue: pensionProfileState.steps[6].endDateTime
			}

			const zeroAltLoaded = {
				rulefunction: trueFalse,
				ruleparam: true,
				rulevalue: context.rootState.pensiondetails?.zeroAlterationAmountsLoaded
			}

			const valuetransferCJNotEnded = {
				rulefunction: undefinedOrNull,
				ruleparam: undefined,
				rulevalue: valuetransferState.endDateTime
			}

			const dateOfEmploymentLessThenSixMonthsAgo = {
				rulefunction: diffDateSmallerThen,
				ruleparam: 6,
				rulevalue: currentEmployments[0] && currentEmployments[0].startDate || new Date(1900, 0, 1) // dummy date in case participant is inactive
			}

			const pensionGoalIsNotReached = {
				rulefunction: smallerThen,
				ruleparam: pensionProfileState.data.netAmount, // pensionGoal
				rulevalue: totalMonthlyNet // expectedPension
			}

			const pensionGoalIsReached = {
				rulefunction: greaterThen,
				ruleparam: pensionProfileState.data.netAmount, // pensionGoal
				rulevalue: totalMonthlyNet // expectedPension
			}

			const extraSavingsIsAvailable = {
				rulefunction: trueFalse,
				ruleparam: true,
				rulevalue: boolExtraSavingsIsAvailable
			}

			const oldAgePensionAvailable = {
				rulefunction: trueFalse,
				ruleparam: true,
				rulevalue: boolHasOldAgePensionAvailable
			}

			const rulesToEvaluate: Array<Rule> = [
				{
					id: "A",
					category: "risicoprofiel_vastvariabel",
					subrules: [
						activeSleeper,
						hasMin10RiskProfiles,
						hasNoPolicyWithOwnInvestmentStrategy,
						riskProfileCJNotStarted,
						notRetiree,
						oldAgePensionAvailable
					]
				},
				{
					id: "B",
					category: "risicoprofiel_vastvariabel",
					subrules: [
						activeSleeper,
						hasMin10RiskProfiles,
						hasNoPolicyWithOwnInvestmentStrategy,
						riskProfileCJStarted,
						riskProfileCJNotEnded,
						notRetiree,
						oldAgePensionAvailable
					]
				},
				{
					id: "C",
					category: "risicoprofiel_vastvariabel",
					subrules: [
						activeSleeper,
						hasMin10RiskProfiles,
						hasNoPolicyWithOwnInvestmentStrategy,
						riskProfileCJStarted,
						riskProfileCJEnded,
						riskProfileCJMoreThenYearAgo,
						notRetiree,
						oldAgePensionAvailable
					]
				},
				{
					id: "D",
					category: "risicoprofiel_vastvariabel",
					subrules: [
						activeSleeper,
						hasMin10RiskProfiles,
						hasNoPolicyWithOwnInvestmentStrategy,
						riskProfileCJStarted,
						riskProfileCJEnded,
						riskProfileCJLessThenYearAgo,
						vastVariabelCJNotStarted,
						age42OrBelow,
						oldAgePensionAvailable
					]
				},
				{
					id: "E",
					category: "risicoprofiel_vastvariabel",
					subrules: [
						activeSleeper,
						hasMin10RiskProfiles,
						hasNoPolicyWithOwnInvestmentStrategy,
						riskProfileCJStarted,
						riskProfileCJEnded,
						riskProfileCJLessThenYearAgo,
						vastVariabelCJStarted,
						vastVariabelCJNotEnded,
						oldAgePensionAvailable
					]
				},
				{
					id: "F",
					category: "risicoprofiel_vastvariabel",
					subrules: [
						activeSleeper,
						hasMin10RiskProfiles,
						hasNoPolicyWithOwnInvestmentStrategy,
						riskProfileCJStarted,
						riskProfileCJEnded,
						riskProfileCJLessThenYearAgo,
						vastVariabelCJNotStarted,
						age43OrAbove,
						oldAgePensionAvailable
					]
				},
				{
					id: "A", // CJ pensioendoel - pensioendoel vaststellen
					category: "pensioendoel_overiginkomen",
					subrules: [
						activeSleeper,
						pensionGoalNotEnded
					]
				},
				{
					id: "B", // CJ pensioendoel - overig inkomen opgeven (npr)
					category: "pensioendoel_overiginkomen",
					subrules: [
						activeSleeper,
						pensionGoalEnded,
						nprNotEnded
					]
				},
				{
					id: "C", // CJ pensioendoel - overig inkomen vernieuwen (npr)
					category: "pensioendoel_overiginkomen",
					subrules: [
						activeSleeper,
						pensionGoalEnded,
						nprEnded,
						nprMoreThenYearAgo
					]
				},
				{
					id: "D", // CJ valuetransfer
					category: "pensioendoel_overiginkomen",
					subrules: [
						notSleeper,
						active,
						dateOfEmploymentLessThenSixMonthsAgo,
						riskProfileCJEnded,
						pensionGoalEnded,
						nprEnded,
						valuetransferCJNotEnded,
						oldAgePensionAvailable,
						notRetiree
					]
				},
				{
					id: "E", // CJ pensioendoel - pensioengat
					category: "pensioendoel_overiginkomen",
					subrules: [
						activeSleeper,
						pensionGoalEnded,
						nprEnded,
						pensionGoalIsNotReached,
						zeroAltLoaded
					]
				},
				{
					id: "F", // CJ bijsparen - doelkapitaal wordt niet bereikt
					category: "pensioendoel_overiginkomen",
					subrules: [
						notSleeper,
						activeSleeper,
						extraSavingsIsAvailable,
						pensionGoalEnded,
						nprEnded,
						pensionGoalIsNotReached,
						zeroAltLoaded,
						oldAgePensionAvailable,
						notRetiree
					]
				},
				{
					id: "G", // CJ bijsparen - doelkapitaal wordt wel bereikt
					category: "pensioendoel_overiginkomen",
					subrules: [
						notSleeper,
						active,
						extraSavingsIsAvailable,
						pensionGoalEnded,
						nprEnded,
						pensionGoalIsReached,
						zeroAltLoaded,
						oldAgePensionAvailable,
						notRetiree
					]
				},
				{
					id: "A",
					category: "planner",
					subrules: [
						active,
						notSleeper,
						oldAgePensionAvailable,
						notRetiree
					]
				},
				{
					id: "A",
					category: "investments",
					subrules: [
						alwaysTrue,
						oldAgePensionAvailable,
						hasRetireeVariablePolicy
					]
				},
				{
					id: "A",
					category: "overig",
					subrules: [
						alwaysTrue
					]
				},
				{
					id: "B",
					category: "overig",
					subrules: [
						alwaysTrue
					]
				}
			]

			const highLightsToCollect = 3

			if (ruleCategories) {
				for (const ruleCat of ruleCategories) {
					const rules = rulesToEvaluate.filter(value => value.category === ruleCat.code)

					for (const rule of rules) {
						const subRulesLength = rule.subrules.length
						let subRulesMatched = 0
						for (const subrule of rule.subrules) {
							if (!subrule.rulefunction(subrule.ruleparam)(subrule.rulevalue)) {
								break
							} else {
								subRulesMatched++
							}

							if (subRulesLength === subRulesMatched) {
								const ruleCode = `${rule.category}_${rule.id}`
								const highLight = highLightsCollection.find(highlight => highlight.code === ruleCode)
								if (highLight) {
									highLigths.push(highLight)
								}

								if (highLigths.length === highLightsToCollect) {
									return highLigths
								}
							}
						}
					}
				}

			} else {
				return highLigths
			}

			return highLigths
		}
	},
	namespaced: true
}

export default stateModule
