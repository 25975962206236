import {RootState} from "../types"
import {Pensiongoal, PensiongoalItem} from "./types"
import {Module} from "vuex"

const pensiongoalState: Pensiongoal = {
	items: [],
	value: 0,
	isEdited: false
}

const pensiongoal: Module<Pensiongoal, RootState> = {
	actions: {},
	getters: {
		total: state => state.value,
		isEdited: state => state.isEdited
	},
	mutations: {
		hydrate(state, data: Pensiongoal) {
			// Fix to make sure data doesn't get overwritten by empty values
			if (!state.isEdited) {
				Object.assign(state, data)
			}
		},
		updateItems(state, items: Array<PensiongoalItem>) {
			Object.assign(state.items, items)
			state.value = state.items.reduce((acc, i) => i.value ? i.value + acc : acc, 0)
			state.isEdited = true
		},
		resetItems(state) {
			Object.assign(state.items, state.items.map(i => ({...i, current: undefined})))
			state.isEdited = false
		},
		setValue(state, value: number) {
			state.value = value
			state.isEdited = true
		}
	},
	namespaced: true,
	state: pensiongoalState
}

export default pensiongoal
