

































































































import Vue from "vue"
import Component from "vue-class-component"
import {namespace} from "vuex-class"

import {
	IconArrow,
	IconDocument,
	IconEnvelope,
	IconFolder,
	IconMapsFilled,
	IconPerson,
	IconPencil,
	IconPensiondetails,
	IconRepeat,
	IconStar,
	IconPiggybank
} from "custom/icons"

import IconLabel from "custom/IconLabel.vue"

import ActionButton from "components/button/ActionButton.vue"
import Badge from "components/supplemental/Badge.vue"
import Card from "components/panel/Card.vue"
import LinkButton from "components/button/LinkButton.vue"
import Modal from "components/modal/Modal.vue"

import eventBus from "lib/vue/eventBus"
import {initialPensionProfileState, PensionProfilePayload, initialValueTransferState, ValuetransferPayload} from "store/state/types"
import {StoreAction} from "lib/types/vuex"
import {AllianzPolicy} from "store/pensiondetails/types"

const messages = namespace("messages")
const dossier = namespace("dossier")
const progressState = namespace("progressState")
const pensiondetails = namespace("pensiondetails")
const policyholder = namespace("policyholder")

@Component({
	components: {
		ActionButton,
		Badge,
		Card,
		IconLabel,
		LinkButton,
		Modal,

		// icons
		IconArrow,
		IconDocument,
		IconEnvelope,
		IconFolder,
		IconMapsFilled,
		IconPerson,
		IconPencil,
		IconPensiondetails,
		IconRepeat,
		IconStar,
		IconPiggybank
	}
})
export default class MenuModal extends Vue {
	@messages.Getter unreadMessages!: number
	@dossier.Getter unreadDocuments!: number
	@progressState.Action getProgressState!: StoreAction<PensionProfilePayload | ValuetransferPayload>
	@pensiondetails.Getter policiesWithMoreThan10RProfiles!: Array<AllianzPolicy>
	@pensiondetails.Getter policiesWithMoreThan3LessThan10RProfiles!: Array<AllianzPolicy>
	@pensiondetails.Getter policiesWithLessThan3RProfiles!: Array<AllianzPolicy>
	@pensiondetails.Getter policyHolderMainStatus!: string
	@pensiondetails.Getter policiesWithOwnInvestmentStrategy!: boolean
	@pensiondetails.Getter isExtraSavingsAvailable!: boolean
	@pensiondetails.Getter hasOldAgePension!: boolean
	@pensiondetails.Getter hasAllianzAdvantageProduct!: boolean
	@pensiondetails.Getter hasPPIProduct!: boolean
	@pensiondetails.Getter hasRetireeVariablePolicy!: boolean
	@pensiondetails.Getter hasRetireeFixedPolicy!: boolean
	@policyholder.Getter isRetired!: boolean

	open = false
	pensionProfileState: PensionProfilePayload = initialPensionProfileState
	valuetransferState: ValuetransferPayload = initialValueTransferState

	hasEnddate(endDate: any): boolean {
		return endDate !== null && endDate.endDateTime !== undefined
	}

	get isSleeper(): boolean {
		return this.policyHolderMainStatus === "Sleeper"
	}

	get isRiskprofileComplete(): boolean {
		return this.hasEnddate(this.pensionProfileState.steps[3])
	}

	get isValueTransferCompleted(): boolean {
		return this.valuetransferState.status === "COMPLETED" ? true : false
	}

	get showPlannerLink(): boolean {
		return this.policyHolderMainStatus === "Active" && !this.isSleeper && this.hasOldAgePension && !this.isRetired
	}

	get showExtraSavingsLink(): boolean {
		return !["Sleeper", "NOACCESS"].includes(this.policyHolderMainStatus) && this.isExtraSavingsAvailable &&
			this.hasOldAgePension && !this.isRetired
	}

	get showValueTransferLink(): boolean {
		return !["Sleeper", "NOACCESS"].includes(this.policyHolderMainStatus) && !this.isValueTransferCompleted && this.hasOldAgePension && !this.isRetired
	}

	get showRiskProfileLink(): boolean {
		return !this.isRetired &&
			!this.policiesWithOwnInvestmentStrategy &&
			this.hasOldAgePension &&
			this.policiesWithLessThan3RProfiles.length === 0
	}

	get showInvestmentLink(): boolean {
		return this.hasOldAgePension && this.hasRetireeVariablePolicy
	}

	opened() {
		this.open = true
		this.$emit("opened")
	}

	closed() {
		this.open = false
		this.$emit("closed")
	}

	async mounted() {
		eventBus.on("menuModal", () => {
			if (this.open) {
				(this.$refs.modal as Modal).close()
				this.open = false
			} else {
				(this.$refs.modal as Modal).display()
				this.open = true
			}
		}, this)
		await Vue.nextTick()
		this.pensionProfileState = await this.getProgressState(this.pensionProfileState)
		this.valuetransferState = await this.getProgressState(this.valuetransferState)
	}

	goToURL(url: string) {
		window.location.href = url
	}
}
