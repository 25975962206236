import { HistoricalLifecycle } from "./types"

export const riskProfileOverrides = new Map([
	["AAP2013_ACT_DEF_25", "PPRP_AAP2013_ACT_DEF"],
	["AAP2013_ACT_NEU_25", "PPRP_AAP2013_ACT_NEU"],
	["AAP2013_ACT_NEU_50", "PPRP_AAP2013_ACT_NEU"],
	["AAP2013_ACT_OFF_25", "PPRP_AAP2013_ACT_OFF"],
	["AAP2013_ACT_OFF_50", "PPRP_AAP2013_ACT_OFF"],
	["AAP2013_ACT_OFF_75", "PPRP_AAP2013_ACT_OFF"],
	["AAP2013_DB_ACT", "PPRP_AAP2013_ACT_NEU"],

	["AAP2013_MAN_DEF_25", "AAP2013_MAN_DEF"],
	["AAP2013_MAN_NEU_25", "AAP2013_MAN_NEU"],
	["AAP2013_MAN_NEU_50", "AAP2013_MAN_NEU"],
	["AAP2013_MAN_OFF_25", "AAP2013_MAN_OFF"],
	["AAP2013_MAN_OFF_50", "AAP2013_MAN_OFF"],
	["AAP2013_MAN_OFF_75", "AAP2013_MAN_OFF"],
	["AAP2013_MAN_DB", "AAP2013_MAN_NEU"],

	["AAP2014_ACT_DEF_25", "PPRP_AAP2014_ACT_DEF"],
	["AAP2014_ACT_NEU_25", "PPRP_AAP2014_ACT_NEU"],
	["AAP2014_ACT_NEU_50", "PPRP_AAP2014_ACT_NEU"],
	["AAP2014_ACT_OFF_25", "PPRP_AAP2014_ACT_OFF"],
	["AAP2014_ACT_OFF_50", "PPRP_AAP2014_ACT_OFF"],
	["AAP2014_ACT_OFF_75", "PPRP_AAP2014_ACT_OFF"],
	["AAP2014_DB_ACT", "PPRP_AAP2014_ACT_NEU"],

	["AAP2014_MAN_DEF_25", "AAP2014_MAN_DEF"],
	["AAP2014_MAN_NEU_25", "AAP2014_MAN_NEU"],
	["AAP2014_MAN_NEU_50", "AAP2014_MAN_NEU"],
	["AAP2014_MAN_OFF_25", "AAP2014_MAN_OFF_25"],
	["AAP2014_MAN_OFF_50", "AAP2014_MAN_OFF_25"],
	["AAP2014_MAN_OFF_75", "AAP2014_MAN_OFF_25"],
	["AAP2014_MAN_DB", "AAP2014_MAN_NEU"],

	["ANP_ACT_DEF_25", "PPRP_ANP_ACT_DEF"],
	["ANP_ACT_NEU_25", "PPRP_ANP_ACT_NEU"],
	["ANP_ACT_NEU_50", "PPRP_ANP_ACT_NEU"],
	["ANP_ACT_OFF_25", "PPRP_ANP_ACT_OFF"],
	["ANP_ACT_OFF_50", "PPRP_ANP_ACT_OFF"],
	["ANP_ACT_OFF_75", "PPRP_ANP_ACT_OFF"],
	["ANP_DB_ACT", "PPRP_ANP_ACT_NEU"],

	["ANP_MAN_DEF_25", "ANP_MAN_DEF"],
	["ANP_MAN_NEU_25", "ANP_MAN_NEU"],
	["ANP_MAN_NEU_50", "ANP_MAN_NEU"],
	["ANP_MAN_OFF_25", "ANP_MAN_OFF"],
	["ANP_MAN_OFF_50", "ANP_MAN_OFF"],
	["ANP_MAN_OFF_75", "ANP_MAN_OFF"],
	["ANP_MAN_DB", "ANP_MAN_NEU"],

	["PPI_MAN_DEF_25", "PPI_MAN_DEF"],
	["PPI_MAN_NEU_25", "PPI_MAN_NEU"],
	["PPI_MAN_NEU_50", "PPI_MAN_NEU"],
	["PPI_MAN_OFF_25", "PPI_MAN_OFF"],
	["PPI_MAN_OFF_50", "PPI_MAN_OFF"],
	["PPI_MAN_OFF_75", "PPI_MAN_OFF"],
	["PPI_MAN_DB", "PPI_MAN_NEU"],

	["AAP_2023_MAN_DEF_25", "AAP_2023_MAN_DEF"],
	["AAP_2023_MAN_NEU_25", "AAP_2023_MAN_NEU"],
	["AAP_2023_MAN_NEU_50", "AAP_2023_MAN_NEU"],
	["AAP_2023_MAN_OFF_25", "AAP_2023_MAN_OFF"],
	["AAP_2023_MAN_OFF_50", "AAP_2023_MAN_OFF"],
	["AAP_2023_MAN_OFF_75", "AAP_2023_MAN_OFF"],
	["AAP_2023_MAN_DB", "AAP_2023_MAN_NEU"],

	["ANP_2023_MAN_DEF_25", "ANP_2023_MAN_DEF"],
	["ANP_2023_MAN_NEU_25", "ANP_2023_MAN_NEU"],
	["ANP_2023_MAN_NEU_50", "ANP_2023_MAN_NEU"],
	["ANP_2023_MAN_OFF_25", "ANP_2023_MAN_OFF"],
	["ANP_2023_MAN_OFF_50", "ANP_2023_MAN_OFF"],
	["ANP_2023_MAN_OFF_75", "ANP_2023_MAN_OFF"],
	["ANP_2023_MAN_DB", "ANP_2023_MAN_NEU"],

	["PPI_2023_MAN_DEF_25", "PPI_2023_MAN_DEF"],
	["PPI_2023_MAN_NEU_25", "PPI_2023_MAN_NEU"],
	["PPI_2023_MAN_NEU_50", "PPI_2023_MAN_NEU"],
	["PPI_2023_MAN_OFF_25", "PPI_2023_MAN_OFF"],
	["PPI_2023_MAN_OFF_50", "PPI_2023_MAN_OFF"],
	["PPI_2023_MAN_OFF_75", "PPI_2023_MAN_OFF"],
	["PPI_2023_MAN_DB", "PPI_2023_MAN_NEU"]
])

export const riskProfilesToExclude = new Set([
	"AAP2013_AP_Gar1",
	"P_PRD_PRC_RP218",
	"AAP2014_AP_Gar1",
	"P_PRD_PRC_RP223",
	"P_PRD_PRC_RP248",
	"P_PRD_PRC_RP249",
	"P_PROD_PROC_RP51",
	"P_PRD_PRC_RP251",
	"P_PRD_PRC_RP252",
	"P_PRD_PRC_RP253",
	"P_PRD_PRC_RP287",
	"AAP_2023_FREE_INVEST",
	"PPI_2023_FREE_INVEST",
	"ANP_2023_FREE_INVEST"
])

export const historicalLifecycles: Array<HistoricalLifecycle> = [
	{
		product: "AAP2013",
		group: "APB",
		lifecycles: [
			{
				name: "AAP2013_AP_Def",
				code: "DEF"
			},
			{
				name: "AAP2013_AP_Neut",
				code: "NEU"
			},
			{
				name: "AAP2013_AP_Off",
				code: "OFF"
			}
		]
	},
	{
		product: "AAP2013",
		group: "EGB",
		lifecycles: [
			{
				name: "P_PRD_PRC_RP213",
				code: "DEF"
			},
			{
				name: "P_PRD_PRC_RP214",
				code: "NEU"
			},
			{
				name: "P_PRD_PRC_RP215",
				code: "OFF"
			},
			{
				name: "P_PRD_PRC_RP216", // Named Standaard but treated like NEU
				code: "NEU"
			}
		]
	},
	{
		product: "AAP2014",
		group: "APB",
		lifecycles: [
			{
				name: "AAP2014_AP_Def",
				code: "DEF"
			},
			{
				name: "AAP2014_AP_Neut",
				code: "NEU"
			},
			{
				name: "AAP2014_AP_Off",
				code: "OFF"
			}
		]
	},
	{
		product: "AAP2014",
		group: "APB",
		lifecycles: [
			{
				name: "AAP2014_AP_Def",
				code: "DEF"
			},
			{
				name: "AAP2014_AP_Neut",
				code: "NEU"
			},
			{
				name: "AAP2014_AP_Off",
				code: "OFF"
			}
		]
	},
	{
		product: "AAP2014",
		group: "EGB",
		lifecycles: [
			{
				name: "P_PRD_PRC_RP219",
				code: "DEF"
			},
			{
				name: "P_PRD_PRC_RP220",
				code: "NEU"
			},
			{
				name: "P_PRD_PRC_RP221",
				code: "OFF"
			},
			{
				name: "P_PRD_PRC_RP222", // Named Standaard but treated like NEU
				code: "NEU"
			}
		]
	},
	{
		product: "ANP",
		group: "EGB",
		lifecycles: [
			{
				name: "P_PRD_PRC_RP244",
				code: "DEF"
			},
			{
				name: "P_PRD_PRC_RP245",
				code: "NEU"
			},
			{
				name: "P_PRD_PRC_RP246",
				code: "OFF"
			},
			{
				name: "P_PRD_PRC_RP247", // Named Standaard but treated like NEU
				code: "NEU"
			}
		]
	},
	{
		product: "26",
		group: "EGB",
		lifecycles: [
			{
				name: "P_PROD_PROC_RP47",
				code: "DEF"
			},
			{
				name: "P_PROD_PROC_RP48",
				code: "NEU"
			},
			{
				name: "P_PROD_PROC_RP49",
				code: "OFF"
			},
			{
				name: "P_PROD_PROC_RP50", // Named Standaard but treated like NEU
				code: "NEU"
			}
		]
	},
	{
		product: "PPI",
		group: "EGB",
		lifecycles: [
			{
				name: "P_PRD_PRC_RP254",
				code: "OFF"
			},
			{
				name: "P_PRD_PRC_RP255",
				code: "DEF"
			},
			{
				name: "P_PRD_PRC_RP256",
				code: "DEF"
			},
			{
				name: "P_PRD_PRC_RP257",
				code: "NEU"
			},
			{
				name: "P_PRD_PRC_RP258",
				code: "OFF"
			}
		]
	}
]
