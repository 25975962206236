/**
 * A function that performs a validation and returns whether it was successful.
 *
 * @this this The object being validated.
 * @param value The value of the field being validated.
 * @param context An object with context information that can be used by the validation.
 */
// The type F should actually be `F extends keyof T` (and the type of the value argument T[F]) but type inference fails if we do that.
export type Validator<F, T, C> = (this: Partial<T>, value: F, context: C) => boolean

export type Scalar = string | number | Date | boolean | File

/**
 * Describes the validation rules for the fields in an object `T`. The rules may use the context object `C` for custom validation.
 */
export type ObjectRules<T, C = undefined> = {
	[F in keyof T]-?: FieldRules<F, T, C>
}

/**
 * Describes the validation rules for a field `F` of object `T` in context `C`.
 */
export interface FieldRules<F extends keyof T, T, C> {
	readonly required: boolean | ((this: Partial<T>, context: C) => boolean)
	readonly type: Type<T[F]>
	readonly values?: ReadonlyArray<NonNullable<T[F]>>

	// String rules:
	readonly maxlength?: T[F] extends string | undefined ? number : never
	readonly minlength?: T[F] extends string | undefined ? number : never
	readonly pattern?: T[F] extends string | undefined ? RegExp : never

	// Number or Date rules:
	readonly maximum?: T[F] extends number | Date | undefined ? T[F] : never
	readonly minimum?: T[F] extends number | Date | undefined ? T[F] : never

	// Nested rules:
	readonly fields?: T[F] extends Array<infer N> ? ObjectRules<N> : T[F] extends object ? ObjectRules<T[F]> : never

	// Custom rules:
	readonly custom?: T[F] extends Scalar | undefined ? {
		// Custom validations are run after the default ones succeed. Therefore, the function
		// receives a value and a context that are both of the expected type. This is contrary to
		// the other validation functions, which receive an unknown value and a partial context.
		readonly [key: string]: Validator<NonNullable<T[F]>, T, C>
	} : never
}

export const enum Datatype { STRING, NUMBER, DATE, BOOLEAN, FILE, ARRAY, OBJECT }

export type Type<T> =
	T extends string ? Datatype.STRING :
	T extends number ? Datatype.NUMBER :
	T extends Date ? Datatype.DATE :
	T extends boolean ? Datatype.BOOLEAN :
	T extends File ? Datatype.FILE :
	T extends Array<unknown> ? Datatype.ARRAY :
	T extends object ? Datatype.OBJECT :
	never

export type GeneralError = "required" | "type" // Not an enum, to follow the convention that the error code matches the key in the field rules object.
export type ScalarErrors = ReadonlyArray<string>
export type ArrayErrors<T> = Map<number, ObjectErrors<T>>

/**
 * Describes the validation result for a single field. The field can be scalar or complex (an array of `T` or an object `T`).
 */
export type FieldErrors<T = unknown> = GeneralError | ScalarErrors | ArrayErrors<T> | ObjectErrors<T> | undefined

/**
 * Describes the validation errors encountered in a context object `T`.
 */
export type ObjectErrors<T> = {
	[F in keyof T]?: FieldErrors
}

export type FieldValidator<F extends keyof T, T, C> = (this: Partial<T>, value: NonNullable<T[F]>, context: C) => FieldErrors

export type ObjectValidator<T, C> = (this: void, object: unknown, context: C) => ObjectErrors<T> | undefined
