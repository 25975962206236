import truncate from "lib/number/truncate"
import timespan from "./timespan"

export enum Diff {
	MILLIS = "ms",
	SECONDS = "s",
	MINUTES = "mn",
	HOURS = "h",
	DAYS = "d",
	WEEKS = "w",
	MONTHS = "m",
	YEARS = "y"
}

const ms = 1
const s = ms * 1000
const mn = s * 60
const h = mn * 60
const d = h * 24
const w = d * 7

const units = {
	d, h, mn, ms, s, w
}

/**
 * Returns the integer number of units that `date1` is less than `date2`.
 */
export default (unit: Diff, date1: Date, date2: Date) => {
	if (unit === Diff.MONTHS || unit === Diff.YEARS) {
		const span = timespan(date1, date2)
		return unit === Diff.MONTHS ? span.years * 12 + span.months : span.years
	} else {
		return truncate((date2.getTime() - date1.getTime()) / units[unit])
	}

}
