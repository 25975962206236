import {Store} from "vuex"
import {RootState} from "./types"
import load from "src/i18n/load"
import {save as saveLocale} from "lib/i18n/session"

import {custom as customController, policyholder as policyholderController, calculator as calculatorApi} from "api/gateway"
import {IncomeItem, AllianzPensionPlan, RiskProfileNamePayload, AllianzPolicy} from "./pensiondetails/types"
import {maxBy, sumBy} from "lodash-es"
import {Employment} from "./policyholder/types"

export default async (store: Store<RootState>): Promise<boolean> => {
	store.commit("timestamp")

	if (!store.state.locale) {
		store.commit("locale", "nl-NL")
		await saveLocale(load, "nl-NL")
	}

	const participantDetails = await policyholderController.getOne()
	if (!participantDetails) {
		return false
	}
	store.commit("policyholder/hydrate", participantDetails)
	await store.dispatch("dossier/load")

	await store.dispatch("messages/load")

	if (participantDetails.retired) {
		const retireePolicies = await customController.getAllianzRetireePolicies()
		store.commit("pensiondetails/setRetireePolicies", retireePolicies)
		return true
	}

	const pensionDetails = await customController.getPensiondetails()
	store.commit("pensiondetails/setAOW", pensionDetails.state)
	const statePensionAge = await customController.getStatePensionAge(participantDetails.personal.birthdate)
	store.commit("pensiondetails/setStatePensionAge", statePensionAge)

	const incomeItems: Array<IncomeItem> = []
	if (pensionDetails.external && pensionDetails.external.length > 0) {
		pensionDetails.external.filter(item => !!item.startDate).map<IncomeItem>(item => ({
			id: item.id,
			type: "PENSION",
			name: item.register ? item.register.provider : "",
			reference: item.register ? item.register.identifier : "",
			startDate: item.startDate ? item.startDate : null,
			endDate: item.endDate ? item.endDate : null,
			benefit: item.benefit,
			referenceDate: item.referenceDate,
			fiscal: "GROSS",
			removable: false,
			editable: false,
			indicative:  item.type === "DEFINED_CONTRIBUTION"
		})).forEach(item => {
			incomeItems.push(item)
		})

		store.commit("pensiondetails/setNPRIncomeItems", incomeItems)
	}

	if (pensionDetails.private && pensionDetails.private.length > 0) {
		pensionDetails.private.forEach(item => {
			store.commit("pensiondetails/replaceIncome", item)
		})
	}

	store.commit("policyholder/setRiskProfileCode", participantDetails.riskProfileCode)

	const totalGrossSalary = sumBy<Employment>(participantDetails.employments.filter(
			item => item.endDate === undefined || item.endDate === null),
		item => item.fulltimeSalary * item.parttimePercentage)
	store.commit("policyholder/setNetYearlySalary", await calculatorApi.netAmount(totalGrossSalary, "salary"))
	store.commit("policyholder/setGrossYearlySalary", totalGrossSalary)
	store.commit("pensiongoal/hydrate", await customController.getPensiongoal())
	const allianzPolicies = await customController.getAllianzPolicies() ?? []
	store.commit("pensiondetails/setAllianzPolicies", allianzPolicies)

	const selectedPolicy: AllianzPolicy | undefined = maxBy(allianzPolicies, policy => policy.participationWeight)
	store.commit("policyholder/setFiscalRoomYearly", selectedPolicy!!.fiscalRoomYearly)
	store.commit("policyholder/setFiscalRoomOneTime", selectedPolicy!!.fiscalRoomOneTime)

	const allianzPensionPlans: Array<AllianzPensionPlan> = []

	const loadPensionPlan = async (policy: AllianzPolicy): Promise<void> => {
		const allianzPensionPlan: AllianzPensionPlan = await store.dispatch("pensiondetails/loadAllianzPensionPlan", {
			product: policy.product,
			pensionPlanID: policy.pensionPlanID
		})

		store.commit("pensiondetails/setAllianzPensionPlan", { policyId: policy.id, pensionPlanInfo: allianzPensionPlan })
		allianzPensionPlans.push(allianzPensionPlan)

		if (allianzPensionPlan.availableRiskProfiles && allianzPensionPlan.availableRiskProfiles.length > 0) {
			const riskProfileName = allianzPensionPlan.availableRiskProfiles.find(riskProfile =>
				riskProfile.key === policy.riskProfileId)?.name ?? ""

			const riskProfileNamePayload: RiskProfileNamePayload = {
				policyId: policy.id,
				riskProfileName
			}
			store.commit("pensiondetails/setAllianzPolicyRiskProfileName", riskProfileNamePayload)
		}
	}

	await Promise.all(allianzPolicies.map(loadPensionPlan))

	return true
}
