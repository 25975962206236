









import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { State } from "vuex-class"

import { Locale } from "store/types"

@Component
export default class Metaphor extends Vue {
	@Prop({required: true, type: Number}) left!: number
	@Prop({required: true, type: Number}) top!: number
	@Prop({required: true, type: Number}) right!: number

	@State locale!: Locale

	// PDE-1101: Format like this instead of via the money filter because the locale may cause some problems on iPad Safari.
	formatNumber(nr: number, decimals: number = 0): string {
		const thousandsSeperator = this.locale === "nl-NL" ? "." : ","
		const decimalsSeperator = this.locale === "nl-NL" ? "," : "."

		const numberString = nr.toFixed(decimals)
		const [whole, fraction] = numberString.split(".")
		let formatted = whole
		const regex = /(\d+)(\d{3})/
		const replaceString = `$1${ thousandsSeperator }$2`
		while (regex.test(formatted)) {
			formatted = formatted.replace(regex, replaceString)
		}
		if (fraction) {
			formatted += `${ decimalsSeperator }${ fraction }`
		}

		return `€ ${ formatted }`
	}
}
