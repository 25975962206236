import arrow from "assets/svg/icoon_arrow.svg?inline"
import checkmark from "assets/svg/checkmark.svg?inline"
import chevron from "assets/svg/chevron.svg?inline"
import close from "assets/svg/default_close.svg?inline"
import digid from "assets/svg/icoon_digid.svg?inline"
import document from "assets/svg/default_document.svg?inline"
import eu from "assets/svg/icoon_eu.svg?inline"
import folder from "assets/svg/folder.svg?inline"
import graph from "assets/svg/stock-data-graph.svg?inline"
import pencil from "assets/svg/edit.svg?inline"
import envelope from "assets/svg/mail.svg?inline"
import envelopeOpened from "assets/svg/email-read-o.svg?inline"
import envelopeFilled from "assets/svg/default_envelope-filled.svg?inline"
import mapsFilled from "assets/svg/icon_maps-filled.svg?inline"
import person from "assets/svg/person.svg?inline"
import personFilled from "assets/svg/icoon_profiel-filled.svg?inline"
import repeat from "assets/svg/icoon_repeat.svg?inline"
import send from "assets/svg/icoon_send_static.svg?inline"
import search from "assets/svg/search.svg?inline"
import star from "assets/svg/star.svg?inline"
import suitcase from "assets/svg/default_suitcase.svg?inline"
import target from "assets/svg/target.svg?inline"
import trashcan from "assets/svg/trash-o.svg?inline"
import upload from "assets/svg/icoon_upload.svg?inline"
import piggybank from "assets/svg/precaution.svg?inline"
import pensiondetails from "assets/svg/icoon_pensioengetallen.svg?inline"
import az from "assets/svg/pensiongoal/a-z.svg?inline"
import bike from "assets/svg/pensiongoal/bike.svg?inline"
import car from "assets/svg/pensiongoal/car-front.svg?inline"
import checkPaper from "assets/svg/pensiongoal/check-paper.svg?inline"
import couch from "assets/svg/pensiongoal/couch.svg?inline"
import healthBag from "assets/svg/pensiongoal/health-bag-doctor.svg?inline"
import house from "assets/svg/pensiongoal/house.svg?inline"
import phone from "assets/svg/pensiongoal/phone-call.svg?inline"
import shoppingCart from "assets/svg/pensiongoal/shopping-cart.svg?inline"
import sportTrainer from "assets/svg/pensiongoal/sport-trainer.svg?inline"
import student from "assets/svg/pensiongoal/student-school-graduation.svg?inline"
import waterTap from "assets/svg/pensiongoal/water-tap.svg?inline"

// tslint:disable:variable-name
export const IconArrow = arrow
export const IconCheckmark = checkmark
export const IconChevron = chevron
export const IconClose = close
export const IconDigid = digid
export const IconDocument = document
export const IconEnvelope = envelope
export const IconEnvelopeOpened = envelopeOpened
export const IconEnvelopeFilled = envelopeFilled
export const IconEu = eu
export const IconGraph = graph
export const IconMapsFilled = mapsFilled
export const IconPerson = person
export const IconPersonFilled = personFilled
export const IconRepeat = repeat
export const IconSend = send
export const IconSearch = search
export const IconStar = star
export const IconSuitcase = suitcase
export const IconTarget = target
export const IconTrashcan = trashcan
export const IconUpload = upload
export const IconPencil = pencil
export const IconFolder = folder
export const IconPiggybank = piggybank
export const IconPensiondetails = pensiondetails
export const IconAz = az
export const IconBike = bike
export const IconCar = car
export const IconCheckPaper = checkPaper
export const IconCouch = couch
export const IconHealthBag = healthBag
export const IconHouse = house
export const IconPhone = phone
export const IconShoppingCart = shoppingCart
export const IconSportTrainer = sportTrainer
export const IconStudent = student
export const IconWaterTap = waterTap
