import {Module} from "vuex"
import {RootState} from "../types"

import {Participant} from "./types"

const participantState: Participant = {
	id: "",
	policyholderId: "",
	locale: "",
	contact: {
		address: undefined,
		email: undefined,
		telephone: undefined
	},
	choices: undefined,
	communication: undefined,
	pension: undefined,
	state: undefined
}

const participant: Module<Participant, RootState> = {
	actions: {

	},
	getters: {

	},
	mutations: {
		hydrate(state, data: Participant) {
			Object.assign(state, data)
		}
	},
	namespaced: true,
	state: participantState
}

export default participant
