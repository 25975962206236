import Vue from "vue"
import {Module} from "vuex"
import {RootState} from "../types"
import {CustomerJourneys, StatusPayload, StepPayload} from "./types"

const customerJourneys: Module<CustomerJourneys, RootState> = {
	state: {},
	getters: {
		notSleeper: (_state, _get, _rootState, rootGetters): boolean => {
			const policyHolderMainStatus: string = rootGetters["pensiondetails/policyHolderMainStatus"]
			return policyHolderMainStatus !== "Sleeper"
		},
		activeSleeper: (_state, _get, _rootState, rootGetters): boolean => {
			const policyHolderMainStatus: string = rootGetters["pensiondetails/policyHolderMainStatus"]
			return policyHolderMainStatus === "Active" || policyHolderMainStatus === "Sleeper"
		},
		active: (_state, _get, _rootState, rootGetters): boolean => {
			const policyHolderMainStatus: string = rootGetters["pensiondetails/policyHolderMainStatus"]
			return policyHolderMainStatus === "Active"
		},
		notRetiree: (_state, _get, _rootState, rootGetters): boolean => {
			return !rootGetters["policyholder/isRetired"]
		},
		oldAgePensionAvailable: (_state, _get, _rootState, rootGetters): boolean => {
			return rootGetters["pensiondetails/hasOldAgePension"]
		},
		zeroAltLoaded: (_state, _get, rootState, _rootGetters): boolean => {
			return rootState.pensiondetails?.zeroAlterationAmountsLoaded ?? false
		},
		hasExtraSavingsAccess: (_state, get, _rootState, rootGetters): boolean => {
			const extraSavingsIsAvailable: boolean  = rootGetters["pensiondetails/isExtraSavingsAvailable"]
			return get.notSleeper &&
				get.notRetiree &&
				get.active &&
				get.oldAgePensionAvailable &&
				get.zeroAltLoaded &&
				extraSavingsIsAvailable
		},
		hasPensionGoalAccess: (_state, get): boolean => {
			return get.activeSleeper && get.zeroAltLoaded
		},
		hasPlannerAccess: (_state, get): boolean => {
			return get.notSleeper && get.notRetiree && get.active && get.oldAgePensionAvailable
		},
		hasRiskProfileAccess: (_state, get, _rootState, rootGetters): boolean => {
			const hasPolicyWithOwnInvestmentStrategy: boolean = rootGetters["pensiondetails/policiesWithOwnInvestmentStrategy"]
			const hasPoliciesWithLessThan3RProfiles: boolean = rootGetters["pensiondetails/policiesWithLessThan3RProfiles"].length > 0
			return get.notRetiree && !hasPolicyWithOwnInvestmentStrategy && get.oldAgePensionAvailable && !hasPoliciesWithLessThan3RProfiles
		},
		hasValueTransferAccess: (_state, get): boolean => {
			return get.notSleeper && get.notRetiree && get.active && get.oldAgePensionAvailable
		}
	},
	mutations: {
		status(state, data: StatusPayload) {
			const customerJourney = state[data.code]
			if (!customerJourney) {
				Vue.set(state, data.code, {
					status: data.status,
					version: data.version
				})
			} else {
				customerJourney.status = data.status
			}
		},
		step(state, data: StepPayload) {
			const customerJourney = state[data.code]
			if (!customerJourney) {
				throw new ReferenceError(`Customer Journey ${data.code} does not exist`)
			}
			if (!customerJourney.steps) {
				Vue.set(customerJourney, "steps", {})
			}
		}
	},
	actions: {
		status: async ({commit}, payload: StatusPayload) => {
			commit("status", payload)
		},
		step: async ({commit}, payload: StepPayload) => {
			commit("step", payload)
		}
	},
	namespaced: true
}

export default customerJourneys
