import {many, one, mandatory} from "lib/import/extract"
import {numeric, datetime, alphanumeric, bool} from "lib/import/convert"
import {ConversionMap, ConversionFunction} from "lib/types/import"
import {RiskProfileData,
		OtherIncomeItems,
		StatePayload,
		RiskProfilePayload,
		PensionProfilePayload,
		PensionGoalData,
		VariablePensionPayload,
		EmailAndCommunicationPreferencePayload,
		EmailAndCommunicationPreference,
		VariablePensionData} from "./types"
import { StateStatus, StateStep } from "api/models/state"

const stateStatus: ConversionFunction<StateStatus> = (v: any) => ["PENDING", "STARTED", "COMPLETED"].includes(v) ? v : undefined
const riskprofile = (v: any) => v && v === "fixed" || v === "variable" ? v : undefined

const required = true
const optional = false

export const step: ConversionMap<StateStep> = {
	endDateTime: ["endDateTime", datetime, optional],
	startDateTime: ["startDateTime", datetime, optional],
	step: ["step", alphanumeric, required],
	weight: ["weight", numeric, required]
}

export const otherIncomeItems: ConversionMap<OtherIncomeItems> = {
	noOtherIncome: ["noOtherIncome", bool, required],
	otherEmployers: ["otherEmployers", bool, required],
	savings: ["savings", bool, required],
	inheritance: ["inheritance", bool, required],
	annuity: ["annuity", bool, required],
	other: ["other", bool, required]
}

export const riskProfile: ConversionMap<RiskProfileData> = {
	hasPartner: ["hasPartner", bool, optional],
	partnerSaves: ["partnerSaves", bool, optional],
	earnsMore: ["earnsMore", alphanumeric, optional],
	rentsHouse: ["rentsHouse", alphanumeric, optional],
	mortgageDone: ["mortgageDone", bool, optional],
	pensiongoal: ["pensiongoal", numeric, required],
	preferredRisk: ["preferredRisk", alphanumeric, optional],
	financialRisk: ["financialRisk", alphanumeric, optional],
	profilePicked: ["profilePicked", alphanumeric, optional],
	otherIncomeItems: ["otherIncomeItems", one(otherIncomeItems), required]
}

export const pensionGoal: ConversionMap<PensionGoalData> = {
	netAmount: ["netAmount", numeric, required],
	pensiongoalPercentage: ["pensiongoalPercentage", numeric, required],
	pensionProfileProgress: ["pensionProfileProgress", numeric, optional],
	pensionExpectedAmount: ["pensionExpectedAmount", numeric, optional],
	pensionGapPercentage: ["pensionGapPercentage", numeric, optional]
}

export const variablePensionData: ConversionMap<VariablePensionData> = {
	profile: ["profile", riskprofile, optional]
}

export const emailAndCommunicationPreference: ConversionMap<EmailAndCommunicationPreference> = {
	emailPopUpShown: ["emailPopUpShown", datetime, optional]
}

export const statePayload: ConversionMap<StatePayload> = {
	key: ["key", alphanumeric, optional],
	status: ["status", stateStatus, required],
	startDateTime: ["startDateTime", datetime, required],
	version: ["version", alphanumeric, required],
	endDateTime: ["endDateTime", datetime, optional],
	steps: ["steps", many(step), required]
}

export const riskProfilePayload: ConversionMap<RiskProfilePayload> = {
	...statePayload,
	data: ["data", one(riskProfile), required]
}

export const variablePensionPayload: ConversionMap<VariablePensionPayload> = {
	...statePayload,
	data: ["data", one(variablePensionData), optional]
}

export const pensionProfilePayload: ConversionMap<PensionProfilePayload> = {
	...statePayload,
	data: ["data", one(pensionGoal), required]
}

export const valuetransferPayload: ConversionMap<StatePayload> = {
	...statePayload
}

export const emailAndCommunicationPreferencePayload: ConversionMap<EmailAndCommunicationPreferencePayload> = {
	...statePayload,
	data: ["data", one(emailAndCommunicationPreference), optional]
}

export const convertRiskProfilePayload = mandatory(one(riskProfilePayload))
export const convertVariablePensionPayload = mandatory(one(variablePensionPayload))
export const convertPensionProfilePayload = one(pensionProfilePayload)
export const convertValuetransferPayload = one(valuetransferPayload)
export const convertEmailAndCommunicationPreferencePayload = one(emailAndCommunicationPreferencePayload)
