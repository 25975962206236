














import Vue from "vue"
import Component from "vue-class-component"
import ActionButton from "./ActionButton.vue"
import IconLabel from "components/supplemental/IconLabel.vue"
import {Prop} from "vue-property-decorator"

@Component({
	components: {
		ActionButton,
		IconLabel
	}
})
export default class Hamburger extends Vue {
	@Prop({type: Boolean, default: false}) disabled!: boolean

	isOpen = false
}
