


















import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Component
export default class AccordionItem extends Vue {
	@Prop({type: Boolean, default: false}) selected?: boolean
}
