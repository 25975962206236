import diff, { Diff } from "lib/date/diff"
import {isUndefined} from "lodash-es"

export interface RuleCategory {
	code: string,
	priority: number
}

interface SubRule {
	rulefunction: any,
	ruleparam: any,
	rulevalue: any
}

export interface Rule {
	id: string,
	category: string,
	subrules: Array<SubRule>
}

export const ruleCategories: Array<RuleCategory> = [
	{
		code: "risicoprofiel_vastvariabel",
		priority: 1
	},
	{
		code: "pensioendoel_overiginkomen",
		priority: 2
	},
	{
		code: "planner",
		priority: 3
	},
	{
		code: "investments",
		priority: 4
	},
	{
		code: "overig",
		priority: 5
	}
]

export const smallerThen = <T extends Date | number>(parameter: T) => (value: T) => value <= parameter
export const greaterThen = <T extends Date | number>(parameter: T) => (value: T) => value >= parameter
export const inArray = <T extends Array<string>>(parameter: T) => (value: string) => parameter.includes(value)
export const trueFalse = (parameter: boolean) => (value: boolean) => value === parameter
export const undefinedOrNull = () => (value: any | undefined) => isUndefined(value)
export const notUndefinedOrNull = () => (value: any | undefined) => !isUndefined(value)
export const diffDateSmallerThen = (parameter: number) => (value: Date) => diff(Diff.MONTHS, value, new Date()) <= parameter
export const diffDateGreaterThen = (parameter: number) => (value: Date) => diff(Diff.MONTHS, value, new Date()) >= parameter
