












































import Vue from "vue"
import {Component, Watch, Prop} from "vue-property-decorator"
import {State, namespace} from "vuex-class"

import PensiongoalInput from "custom/PensiongoalInput.vue"
import RangeInput from "custom/RangeInput.vue"

import ActionButton from "components/button/ActionButton.vue"
import {Pensiongoal, PensiongoalSave} from "store/pensiongoal/types"

import {cloneDeep} from "lodash-es"

import smoothScroll from "components/@directives/smoothscroll"
import {custom as customController} from "api/gateway"
import {initialPensionProfileState, PensionProfilePayload} from "store/state/types"
import {StoreAction} from "lib/types/vuex"
import {Policyholder} from "../api/models/policyholder"

const pensiongoal = namespace("pensiongoal")
const progressState = namespace("progressState")
const policyholder = namespace("policyholder")

@Component({
	components: {
		ActionButton,
		PensiongoalInput,
		RangeInput
	},
	directives: {
		smoothScroll
	}
})
export default class PensiongoalForm extends Vue {
	@Prop({default: "", type: String, required: false}) smoothScrollId!: string
	@pensiongoal.State pensionGoal!: Pensiongoal
	@pensiongoal.State items!: Pensiongoal["items"]
	@pensiongoal.Getter isEdited!: boolean
	max = 9999
	pensiongoalItems: Pensiongoal["items"] = []

	@State policyholder!: Policyholder
	@progressState.Action getProgressState!: StoreAction<PensionProfilePayload>
	@progressState.Action updateProgressState!: StoreAction<PensionProfilePayload>
	@policyholder.Getter netMonthlySalary!: number

	pensionProfileState: PensionProfilePayload = initialPensionProfileState

	@Watch("items", {deep: true})
	pgChanged() {
		this.pensiongoalItems = cloneDeep(this.items)
	}

	async mounted() {
		await this.$nextTick()
		await this.pgChanged()
		await this.setAverages()
		this.pensionProfileState = await this.getProgressState(this.pensionProfileState)
	}

	async changeProgressState(newState: PensionProfilePayload) {
		await this.updateProgressState(newState)
	}

	async saveResult() {
		if (this.isValid) {
			this.$store.commit("pensiongoal/updateItems", this.pensiongoalItems)
			this.$emit("saved")
			const pensiongoalToSave: PensiongoalSave = {
				default: 1800,
				items: this.pensiongoalItems
			}
			pensiongoalToSave.items = this.pensiongoalItems.filter(i => delete i.average)
			await customController.savePensiongoalAverages(pensiongoalToSave)
			this.pensionProfileState.steps[5].endDateTime = new Date()
			this.pensionProfileState.data.netAmount = this.netMonthly
			await this.setPensionGapData()

			await this.changeProgressState(this.pensionProfileState)
		}
	}

	deselectAverage() {
		this.pensiongoalItems = this.pensiongoalItems.map(i => ({...i, value: undefined}))
	}

	selectAverage() {
		this.pensiongoalItems = this.pensiongoalItems.map(i => ({...i, value: i.average}))
	}

	async setAverages() {
		const pensiongoalAverage: Pensiongoal = await customController.getPensiongoalAverages(
			this.policyholder.personal.gender,
			this.policyholder.civilStatus,
			this.netMonthlySalary)
		if (this.pensiongoalItems.length > 0) {
			this.pensiongoalItems = this.pensiongoalItems.map(i => ({...i, average: pensiongoalAverage.items.filter(item =>
		i.category === item.category)[0].value}))
		} else {
			this.pensiongoalItems = pensiongoalAverage.items.map(i => ({...i, value: undefined, average: i.value}))
		}
	}

	async setPensionGapData() {
		const pensionGoalAmount = this.pensionProfileState.data.netAmount
		const pensionExpectedAmount = this.pensionProfileState.data.pensionExpectedAmount || 0
		this.pensionProfileState.data.pensionGapPercentage = Math.round((pensionGoalAmount - pensionExpectedAmount) * 100 / pensionGoalAmount)
	}

	scrollOptions(step: string) {
		return {
			offset: -105,
			duration: 1000,
			target: step,
			delay: 100
		}
	}

	get isValid() {
		return this.pensiongoalItems.every(item => !item.value || (Number(item.value) >= 0 && Number(item.value) <= this.max))
	}

	get valuesChanged(): boolean {
		return this.pensiongoalItems.some(i => i.value !== undefined)
	}

	get netMonthly(): number {
		return this.pensiongoalItems.reduce((acc, i) => i.value ? i.value + acc : acc, 0)
	}
}
