import {IncomeItem,
		AOW,
		AllianzPolicy,
		AllianzPensionPlan,
		TransactionItem,
		TransactionObject,
		AmountGroup,
		TransactionTypeGroup,
		RiskProfileCode,
		Fund,
		UnitPriceObject,
		FundUnitPriceObject,
		PaymentFrequency,
		BenefitType,
		PartnerDeathCoverage,
		RetireeHighLow,
		AllianzRetireePolicy} from "./types"
import {ConversionMap, ConversionFunction} from "lib/types/import"
import {one, many, mandatory, obj} from "lib/import/extract"
import {numeric, alphanumeric, bool, date} from "lib/import/convert"
import {fiscalType} from "lib/import/personalConvert"
import {plannerIncomeType} from "custom/planner/types"

const amountGroup: AmountGroup = {
	pessimisticCapital: 0,
	expectedCapital: 0,
	optimisticCapital: 0,
	pessimisticAnnuity: 0,
	expectedAnnuity: 0,
	optimisticAnnuity: 0,
	pessimisticRealAnnuity: 0,
	expectedRealAnnuity: 0,
	optimisticRealAnnuity: 0
}

const defaultPensionPlan: AllianzPensionPlan = {
	availableRiskProfiles: [],
	hasMin10RiskProfiles: false,
	pensioenklik_Regeling: false,
	bijsparen_Regeling: false,
	startDate: new Date()
}

const required = true
const optional = false

const fund: ConversionMap<Fund> = {
	key: ["key", alphanumeric, required],
	value: ["value", numeric, required],
	units: ["units", numeric, required]
}

const allianzPolicy: ConversionMap<AllianzPolicy> = {
	id: ["polisNummer", alphanumeric, required],
	name: ["polisOmschrijving", alphanumeric, required],
	grossAnnualSalary: ["pensioengevendSalaris", numeric, required],  // TODO: Normaal salaris
	pensionableAnnualSalary: ["pensioengevendSalaris", numeric, required],
	pensionablePay: ["pensioenGrondslag", numeric, required],
	franchise: ["franchise", numeric, required],
	insurancesBalance: ["insurancesBalance", numeric, required],
	currentFunds: ["currentFunds", many(fund), required],
	currentPremium: ["dc_premie", numeric, required],
	currentPremiumTerm: ["dc_premie_termijn", alphanumeric, optional],
	scalePercentage: ["beschikbarePremiePercentage", numeric, required],
	participationWeight: ["participationWeight", numeric, required],
	status: ["policyStatus", alphanumeric, optional],
	pensionPlanID: ["pensionPlanID", alphanumeric, required],
	product: ["product", alphanumeric, required],
	pp_Verzekerd: ["pp_Verzekerd", numeric, required],
	wzp_Verzekerd: ["wzp_Verzekerd", numeric, required],
	anw: ["anw", numeric, required],
	waoHiaat: ["waoHiaat", numeric, required],
	waoExcedent: ["waoExcedent", numeric, required],
	standDatum: ["standDatum", date, required],
	pensioenDatum: ["pensioenDatum", date, required],
	annual: ["nonexistent", () => 0, required],
	expectedCapital: ["nonexistent", () => 0, required],
	riskProfileId: ["riskProfileId", alphanumeric, required],
	riskProfileName: ["nonexistent", () => "UNKNOWN", required],
	riskProfileOverride: ["nonexistent", () => "UNKNOWN", required],
	transactions: ["nonexistent", () => new Map(), required],
	zeroAlterationAmounts: ["nonexistent", () => amountGroup, required],
	endOfParticipationAmounts: ["nonexistent", () => amountGroup, required],
	pensionPlanInfo: ["nonexistent", () => defaultPensionPlan, required],
	fiscalRoomYearly: ["fiscalRoomYearly", numeric, required],
	fiscalRoomOneTime: ["fiscalRoomOneTime", numeric, required],
	hasOldAgePension: ["hasOldAgePension", bool, required],
	startDate: ["startDatumDeelname", date, required],
	alterationDate: ["alterationDate", date, required],
	alterationDescription: ["alterationDescription", alphanumeric, required],
	employmentId: ["employmentId", alphanumeric, required],
	voluntaryPartnerPension: ["voluntaryPartnerPension", numeric, required]
}

const riskProfileCode: ConversionMap<RiskProfileCode> = {
	key: ["key", alphanumeric, required],
	name: ["name", alphanumeric, required]
}

export const allianzPensionPlan: ConversionMap<AllianzPensionPlan> = {
	availableRiskProfiles: ["pensionRiskProfile", many(riskProfileCode), optional],
	hasMin10RiskProfiles: ["hasMin10RiskProfiles", bool, required],
	pensioenklik_Regeling: ["pensioenklik_Regeling", bool, required],
	bijsparen_Regeling: ["bijsparen_Regeling", bool, required],
	startDate: ["startDate", date, required]
}

const aow: ConversionMap<AOW> = {
	title: ["nonexistent", () => "AOW", required],
	id: ["id", alphanumeric, optional],
	civilStatusNpr: ["civilStatusNpr", alphanumeric, optional],
	typeOfAOW: ["nonexistent", () => undefined, optional],
	single: ["state.benefit.single", numeric, required],
	partner: ["state.benefit.partner", numeric, required],
	removable: ["removable", bool, optional],
	editable: ["editable", bool, optional],
	startDate: ["state.startDate", date, required],
	referenceDate: ["state.referenceDate", date, required]
}

const incomeItem: ConversionMap<IncomeItem> = {
	name: ["name", alphanumeric, required],
	reference: ["reference", alphanumeric, optional],
	id: ["id", alphanumeric, optional],
	type: ["type", plannerIncomeType, required],
	benefit: ["benefit", numeric, required],
	startDate: ["startDate", date, required],
	endDate: ["endDate", date, optional],
	fiscal: ["fiscal", fiscalType, required],
	referenceDate: ["referenceDate", date, required],
	removable: ["removable", bool, optional],
	editable: ["editable", bool, optional],
	indicative: ["externalCoverType", bool, optional]
}

// const saving: ConversionMap<Saving> = {
// 	currentWorth: ["currentWorth", numeric, required],
// 	increment: ["increment", numeric, required],
// 	period: ["period", period, required]
// }

const transactionItem: ConversionMap<TransactionItem> = {
	fundCode: ["fundCode", alphanumeric, required],
	fundName: ["fundName", alphanumeric, required],
	rate: ["rate", numeric, required],
	units: ["units", numeric, required],
	value: ["value", numeric, required]
}

const transactionObject: ConversionMap<TransactionObject> = {
	date: ["date", date, required],
	transactionItem: ["transactionItem", one(transactionItem), required],
	type: ["type", alphanumeric, required]
}

const transactionTypeGroup: ConversionFunction<TransactionTypeGroup> = obj(many(transactionObject))

const transactionDateGroup: ConversionFunction<Map<number, TransactionTypeGroup>> = value => {
	const result = new Map<number, TransactionTypeGroup>()

	for (const [key, val] of Object.entries(value)) {
		const convertedDate = date(key)
		const group = transactionTypeGroup(val)
		if (convertedDate === undefined || group === undefined) {
			throw new TypeError(`Field slecht`)
		}
		result.set(convertedDate.valueOf(), group)
	}
	return result
}

const amountGroupObject: ConversionMap<AmountGroup> = {
	pessimisticCapital: ["pessimisticCapital", numeric, required],
	expectedCapital: ["expectedCapital", numeric, required],
	optimisticCapital: ["optimisticCapital", numeric, required],
	pessimisticAnnuity: ["pessimisticAnnuity", numeric, required],
	expectedAnnuity: ["expectedAnnuity", numeric, required],
	optimisticAnnuity: ["optimisticAnnuity", numeric, required],
	pessimisticRealAnnuity: ["pessimisticRealAnnuity", numeric, required],
	expectedRealAnnuity: ["expectedRealAnnuity", numeric, required],
	optimisticRealAnnuity: ["optimisticRealAnnuity", numeric, required]
}

const unitPriceObject: ConversionMap<UnitPriceObject> = {
	date: ["date", date, required],
	unitPrice: ["unitPrice", numeric, required]
}

const fundUnitPriceObject: ConversionMap<FundUnitPriceObject> = {
	key: ["key", alphanumeric, required],
	unitPrices: ["unitPrices", many(unitPriceObject), required]
}

// Retiree

export const paymentFrequency: ConversionFunction<PaymentFrequency> = (v: any) =>
	["YEARLY", "HALF_YEARLY", "TRIMESTER", "QUARTERLY", "TWO_MONTHLY", "MONTHLY"].includes(v) ? v : undefined

export const benefitType: ConversionFunction<BenefitType> = (v: any) =>
	["DIP_VAST", "DIP_VARIABLE", "DIL", "DIL_VARIABLE"].includes(v) ? v : undefined

export const partnerDeathCoverage: ConversionMap<PartnerDeathCoverage> = {
	percentage: ["percentage", numeric, required],
	amount: ["amount", numeric, required]
}

export const retireeHighLowCoverage: ConversionMap<RetireeHighLow> = {
	highFactor: ["highFactor", numeric, required],
	endDateHigh: ["endDateHigh", date, required]
}

export const allianzRetireePolicy: ConversionMap<AllianzRetireePolicy> = {
	grossPerYear: ["grossPerYear", numeric, required],
	benefitAmount: ["benefitAmount", numeric, required],
	annuity: ["annuity", numeric, required],
	id: ["policyNumber", alphanumeric, required],
	policySequenceNr: ["policySequenceNr", alphanumeric, required],
	type: ["benefitType", benefitType, required],
	paymentFrequency: ["paymentFrequency", paymentFrequency, required],
	partner: ["partner", one(partnerDeathCoverage), optional],
	benefitEndDate: ["benefitEndDate", date, optional],
	insurancesBalance: ["insurancesBalance", numeric, required],
	highLow: ["highLow", one(retireeHighLowCoverage), optional],
	currentFunds: ["currentFunds", many(fund), required],
	transactions: ["nonexistent", () => new Map(), required]
}

export const convertAllianzPolicies = many(allianzPolicy)
export const convertAllianzRetireePolicies = many(allianzRetireePolicy)
export const convertPensionPlan = mandatory(one(allianzPensionPlan))
export const convertAOW = mandatory(one(aow))
export const convertIncomeItem = mandatory(one(incomeItem))
export const convertTransactions = many(transactionObject)
export const convertAmountGroup = one(amountGroupObject)
export const convertTransactionDateGroup = transactionDateGroup
export const convertUnitPrices = many(fundUnitPriceObject)
