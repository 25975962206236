import always from "lib/function/always"
import keys from "lib/misc/keys"
import {ConversionMap, ConversionFunction} from "lib/types/import"
import {Predicate, Function1} from "lib/types/function"
import {get, isArray, isNil, isPlainObject, isUndefined} from "lodash-es"

/**
 * Extracts fields from the data object and produces a `T`.
 *
 * @argument {ConversionMap<T>} map The conversion map.
 * @argument {any} data The incoming data object.
 * @returns {Partial<T>} The object with converted values.
 */
export const extract = <T>(map: ConversionMap<T>, data: any): T =>
	Object.fromEntries(
		keys(map).map(field => {
			const [path, convert, required] = map[field]
			const value = get(data, path)
			const result = convert(value)
			if ((required || !isNil(value)) && isUndefined(result)) {
				throw new TypeError(`Field [${path}] ${required ? "does not exist or" : ""} contains an improper value.`)
			}
			return [field, result as any] // FIXME: improve cast or remove completely.
		})
	) as T

/**
 * Returns a conversion function based on the conversion map.
 */
export const one = <T>(map: ConversionMap<T>): ConversionFunction<T> => data => isNil(data) ? undefined : extract(map, data)

/**
 * Returns a `ConversionFunction` that converts an incoming array to an array of `T`'s.
 *
 * @argument {ConversionMap<T>} map The conversion map.
 * @argument {Predicate<T>} predicate Optional predicate function, to filter the incoming array before conversion.
 * @returns {ConversionFunction<Array<T>>} The conversion function.
 */
export const many = <T>(
	mapOrConvert: ConversionMap<T> | ConversionFunction<T>,
	predicate: Predicate<any> = always
): ConversionFunction<Array<T>> => {
	const convert = typeof mapOrConvert === "function" ? mapOrConvert : one(mapOrConvert)
	return values => isArray(values) ? values.filter(predicate).map(mandatory(convert)) : undefined
}

/**
 * Returns a `ConversionFunction` that converts only the last element of an incoming array to an instance of type `T`.
 *
 * @argument {ConversionMap<T>} map The conversion map.
 * @argument {Predicate<T>} predicate Optional predicate function, to filter the incoming array before conversion.
 * @returns {ConversionFunction<T>} The conversion function.
 */
export const last = <T>(map: ConversionMap<T>, predicate: Predicate<any> = always): ConversionFunction<T> =>
	values => {
		const array = isArray(values) && values.filter(predicate)
		if (array && array.length) {
			return extract(map, array[array.length - 1])
		}
		return undefined
	}

/**
 * Returns a `ConversionFunction` that converts an object's values.
 *
 * @argument {ConversionFunction<V>} convert The function to convert individual values in the object.
 * @returns {ConversionFunction<Record<keyof K, V>>} The conversion function.
 */
export const obj = <K, V>(convert: ConversionFunction<V>): ConversionFunction<Record<keyof K, V>> =>
	data => {
		if (isPlainObject(data)) {
			return Object.fromEntries(
				keys<K>(data).map(key => {
					return [key, convert(data[key])]
				})
			) as Record<keyof K, V>
		}
		return undefined
	}

/**
 * Returns a function that enforces that the value can be converted, or throws an error.
 */
export const mandatory = <T>(f: ConversionFunction<T>): Function1<any, T> =>
	data => {
		const result = f(data)
		if (isUndefined(result)) {
			throw new TypeError(`Missing value or cannot convert: ${JSON.stringify(data)}`)
		}
		return result
	}

/**
 * Returns a function that returns the default value if the value is undefined.
 */
export const def = <T>(defaultValue: T, f: ConversionFunction<T>): ConversionFunction<T> =>
	(data: any) => f(isUndefined(data) ? defaultValue : data)

/**
 * Returns a `ConversionFunction` that converts the value to either type.
 */
export const or = <T, U>(f: ConversionFunction<T>, g: ConversionFunction<U>): ConversionFunction<T | U> =>
	(data: any) => f(data) || g(data)

/**
 * Returns a `ConversionFunction` that returns the value if it is contained in the given values.
 */
export const oneOf = <T>(...values: Array<T>): ConversionFunction<T> => {
	const set = new Set(values)
	return value => set.has(value) ? value : undefined
}
