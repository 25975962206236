









import Hamburger from "components/button/Hamburger.vue"

import Vue from "vue"
import Component from "vue-class-component"

@Component({
	components: {
		Hamburger
	}
})
export default class NavigationBar extends Vue {
	active = false
}
