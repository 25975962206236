import {Locale, Plural} from "lib/types/i18n"

export const locale: Locale = {
	separator: {
		thousands: ".",
		decimals: ",",
		and: " en ",
		list: ", "
	},
	currency: {
		code: "EUR",
		symbol: "€",
		prefix: true
	},
	months: {
		long: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
		short: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
	},
	days: {
		long: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
		short: ["zon", "maa", "din", "woe", "don", "vri", "zat"]
	},
	date: {
		short: ["dd", "-", "mm", "-", "yyyy"],
		long: ["d", " ", "mmmm", " ", "yyyy"]
	},
	time: {
		short: ["HH", ":", "mm"],
		long: ["HH", ":", "mm", ":", "ss"]
	},
	gender: {
		female: "Vrouw",
		male: "Man",
		third: "Derde geslacht",
		unknown: "Onbekend"
	},
	maritalStatus: {
		legalCohabitation: "Geregistreerd partnerschap",
		livingTogether: "Samenwonend",
		married: "Getrouwd",
		divorced: "Gescheiden",
		single: "Alleenstaand",
		unknown: "Onbekend"
	},
	status: {
		disabled: "Arbeidsongeschikt",
		active: "Actief",
		inactive: "Inactief",
		pensioner: "Pensioengerechtigd",
		unknown: "Onbekend"
	}
}

export const plural: Plural = {
	months: ["maand", "maanden"],
	years: ["jaar", "jaar"],
	days: ["dag", "dagen"]
}
