




import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class LinkButton extends Vue {
	@Prop({default: false, type: String}) href!: string
	@Prop({default: "_self", type: String}) target!: string
	@Prop({default: false, type: Boolean}) disabled!: boolean

	clicked(e: MouseEvent) {
		if (this.disabled) {
			e.preventDefault()
		} else {
			this.$emit("click", e)
		}
	}
}
