import {IdentificationStrategy, UsernamePassword, isJwtToken} from "lib/types/security"
import RequestStrategy from "lib/request/RequestStrategy"
import RequestError from "lib/request/RequestError"
import HttpStatus from "lib/request/status"
import {ConversionFunction} from "lib/types/import"
import identity from "lib/function/identity"
import { StorageOptions } from "lib/types/storage"
import StorageController from "lib/storage/StorageController"

const jwtIdentifier: ConversionFunction<string> = data => isJwtToken(data) ? data.username : undefined

const DEFAULT_KEY = "credentials"

/**
 * Identification strategy that sends a POST request containing credentials for identification.
 */
export default class CredentialsIdentification<C = UsernamePassword, P = any, T = any> implements IdentificationStrategy<C, T, P> {
	private readonly storage: StorageController
	private readonly key: string
	private _isIdentified: boolean = false
	private _identifier: string | undefined = undefined

	constructor(
		options: StorageOptions,
		private readonly gateway: RequestStrategy,
		private readonly identificationEndpoint: string,
		private readonly verifyLogoutEndpoint?: string,
		private readonly extractToken: ConversionFunction<T> = identity,
		private readonly extractIdentifier: ConversionFunction<string> = jwtIdentifier
	) {
		this.storage = options.storage
		this.key = options.key || DEFAULT_KEY
		this.identifier = this.storage.retrieve(this.key)
	}

	get isIdentified(): boolean {
		return this._isIdentified
	}

	async identify(credentials: C): Promise<T> {
		if (this.isIdentified) {
			return Promise.reject(new TypeError("Already identified"))
		}

		const response = await this.gateway.request("POST", this.identificationEndpoint, credentials)

		switch (response.status) {
			case HttpStatus.OK:
				const data = await response.json()
				this.identifier = this.extractIdentifier(data)
				const token = this.extractToken(data)
				if (token) {
					return token
				}
				return Promise.reject(new TypeError("Invalid token"))
			case HttpStatus.UNAUTHORIZED:
				return Promise.reject(new RequestError(response))
			default:
				return Promise.reject(new RangeError(`Unexpected status code ${response.status}`))
		}
	}

	async unidentify(payload?: P): Promise<boolean> {
		if (this.isIdentified) {
			if (await this.verifyLogout(payload)) {
				this._isIdentified = false
				this.storage.discard(this.key)
				return true
			}
		}
		return false
	}

	get identifier(): string | undefined {
		return this._identifier
	}

	set identifier(identifier: string | undefined) {
		this._identifier = identifier
		if (identifier) {
			this._isIdentified = true
			this.storage.store(this.key, identifier)
		} else {
			this._isIdentified = false
			this.storage.discard(this.key)
		}
	}

	private async verifyLogout(payload?: P): Promise<boolean> {
		if (!this.verifyLogoutEndpoint) {
			return true
		}

		const response = await this.gateway.request("POST", this.verifyLogoutEndpoint, payload)
		return response.ok
	}

}
