export interface TimeSpan {
	readonly days: number
	readonly months: number
	readonly years: number
}

export default (from: Date, to: Date): TimeSpan => {
	const yearFrom = from.getFullYear()
	const monthFrom = from.getMonth()
	const dayFrom = from.getDate()

	const yearTo = to.getFullYear()
	const monthTo = to.getMonth()
	const dayTo = to.getDate()

	const daysInMonth = [31, yearTo % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

	let diffYears = yearTo - yearFrom
	let diffMonths = monthTo - monthFrom
	let diffDays = dayTo - dayFrom

	if (diffMonths < 0) {
		diffYears -= 1
		diffMonths += 12
	}
	if (diffDays < 0) {
		const prevMonth = monthTo > 0 ? monthTo - 1 : 11
		if (diffMonths === 0) {
			diffYears -= 1
			diffMonths = 11
		} else {
			diffMonths -= 1
		}
		diffDays = Math.max(daysInMonth[prevMonth] - dayFrom, 0) + dayTo
	}

	return {
		days: diffDays,
		months: diffMonths,
		years: diffYears
	}
}
