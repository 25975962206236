import { Profiles } from "store/riskprofile/types"

export interface Variablepension {
	profiles: {
		fixed: MetaphorData
		variable: MetaphorData
	}
	variablePensions: VariablePensions
	policyVariableCombinationLoaded: Array<PolicyVariableCombination>
	variablePensionChartsLoaded: boolean
}

export interface PolicyVariableCombination {
	riskProfileShortCode: Profiles,
	variableProfileCode: VariablePensionShortCodes
}

export interface VariableMetaphors {
	["VAST_100"]: MetaphorData
	["VAST_75"]: MetaphorData
	["VAST_50"]: MetaphorData
	["VAST_25"]: MetaphorData
	["VAST_0"]: MetaphorData
}

export interface VariablePensions {
	["DEF"]: Pick<VariableMetaphors, "VAST_100" | "VAST_75">
	["NEU"]: Pick<VariableMetaphors, "VAST_100" | "VAST_75" | "VAST_50">
	["OFF"]: VariableMetaphors
}

export type VariablePensionShortCodes = "VAST_100" | "VAST_75" | "VAST_50" | "VAST_25" | "VAST_0"

export const variablePensionsDEF = ["VAST_100", "VAST_75"]
export const variablePensionsNEU = [...variablePensionsDEF, "VAST_50"]
export const variablePensionsOFF = [...variablePensionsNEU, "VAST_25"]

export interface MetaphorData {
	pessimistic: number,
	expected: number,
	optimistic: number
}
