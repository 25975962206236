import {Pensiongoal, PensiongoalItem} from "./types"
import {ConversionMap} from "lib/types/import"
import {one, many, mandatory} from "lib/import/extract"
import {numeric, alphanumeric} from "lib/import/convert"

const required = true
const optional = false

const mapDefault = (value?: number) => typeof value === "number" ? value : 0

const pgItem: ConversionMap<PensiongoalItem> = {
	category: ["category", alphanumeric, required],
	value: ["value", numeric, required],
	average: ["nonexistent", () => 0, optional]
}

const pensiongoal: ConversionMap<Pensiongoal> = {
	items: ["items", many(pgItem), required],
	value: ["default", mapDefault, required],
	isEdited: ["nonexistent", () => false, required]
}

export const convert = mandatory(one(pensiongoal))
