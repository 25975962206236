import {restApiRootUrl} from "api/settings"
import {getJson, post} from "api/request"
import {NetResult} from "api/models/calculator"
import {PlannerIncomeType} from "custom/planner/types"
import {Income} from "components/chart/types"
import {Policyholder} from "api/models/policyholder"
import toAge from "lib/number/toAge"
import fromAge from "lib/number/fromAge"

export default class CalculatorController {
	async netAmount(amount: number, type: string, civilStatus?: boolean): Promise<NetResult> {
		return getJson<NetResult>(`${restApiRootUrl}/calculation/net/${type}/${amount}?single=${civilStatus}`)
	}

	/**
	 * Calculates the net income amounts, distributed over periods.
	 * The incomes array is assumed to contain monthly incomes.
	 */
	async netBlocks(incomes: ReadonlyArray<Income<PlannerIncomeType>>, policyholder: Policyholder, isSingle?: boolean): Promise<ReadonlyArray<Income<"PENSION">>> {
		const currentPartner = policyholder.partners ? policyholder.partners.filter(partner => partner.civilStatus.endDate !== null)[0] : undefined
		const result = await post<ReadonlyArray<any>>(`${restApiRootUrl}/calculation/net/block`, {
			participantDateOfBirth: policyholder.personal.birthdate,
			isSingle,
			partnerDateOfBirth: !isSingle ? currentPartner?.personal.birthdate : null,
			incomes: incomes.map(
				income => ({
					...income,
					amount: income.amount * 12,
					from: income.from ? toAge(income.from) : null,
					to: income.to ? toAge(income.to) : null,
					owner: "PARTICIPANT"
				})
			)
		})

		return result.map(
			income => ({
				...income,
				amount: income.amount / 12,
				from: income.from ? fromAge(income.from) : undefined,
				to: income.to ? fromAge(income.to) : undefined,
				type: "NET"
			})
		)
	}
}
