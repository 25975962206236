import { IdentificationStrategy } from "lib/types/security"
import { AuthenticationStorage } from "./AuthenticationStorage"
import { isUndefined, values } from "lodash-es"

export default class StoredIdentification<T> implements IdentificationStrategy<T> {
	private readonly identificationStrategies: { [key: string]: IdentificationStrategy<T> }
	private readonly authenticationStorage: AuthenticationStorage

	constructor(identificationStrategies: { [key: string]: IdentificationStrategy<T> }, authenticationStorage: AuthenticationStorage) {
		this.identificationStrategies = identificationStrategies
		this.authenticationStorage = authenticationStorage
	}

	private get identification(): IdentificationStrategy<T> | undefined {
		const identificationStrategies = values(this.identificationStrategies)
		return identificationStrategies.find(identification => identification.isIdentified)
	}

	get isIdentified(): boolean {
		return this.identification?.isIdentified ?? false
	}

	async identify(credentials: T): Promise<any> {
		const authenticationMethod = this.authenticationStorage.authenticationMethod

		if (isUndefined(authenticationMethod)) {
			throw Error("Unknown authentication method")
		}

		const identification = this.identificationStrategies[authenticationMethod]
		const token = await identification.identify(credentials)
		return token
	}

	async unidentify(): Promise<boolean> {
		const success = await this.identification?.unidentify() ?? false

		if (success) {
			this.authenticationStorage.authenticationMethod = undefined
		}

		return success
	}

	get identifier(): string | undefined {
		return this.identification?.identifier
	}

	set identifier(identifier: string | undefined) {
		if (this.identification) {
			this.identification.identifier = identifier
		}
	}
}
