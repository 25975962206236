import {State, StateStep} from "api/models/state"
import { Term } from "custom/planner/types"

export * from "api/models/state"

export interface StateModule {
	[key: string]: State
}

export interface StatePayload {
	key?: string
	startDateTime: Date
	endDateTime?: Date
	version: State["version"]
	status: State["status"]
	steps: Array<StateStep>
}

export interface RiskProfilePayload extends StatePayload {
	data: RiskProfileData
}

export interface PensionProfilePayload extends StatePayload {
	data: PensionGoalData
}

export interface ValuetransferPayload extends StatePayload {
	data?: ValuetransferData
}

export interface VariablePensionPayload extends StatePayload {
	data?: VariablePensionData
}

export interface ExtraSavingsPayload extends StatePayload {
	data?: ExtraSavingsData
}

export interface EmailAndCommunicationPreference {
	emailPopUpShown?: Date | null
}

export interface EmailAndCommunicationPreferencePayload extends StatePayload {
	data?: EmailAndCommunicationPreference
}

const initialEmailSteps: Array<StateStep> = [
	{ step: "1", weight: 100 }
]

export const initialEmailAndCommunicationState: EmailAndCommunicationPreferencePayload = {
	key: "CJ_EMAILANDCOMMUNICATIONPREFERENCES",
	version: "1.0",
	status: "PENDING", // always pending, looking at the steps for substatus
	startDateTime: new Date(),
	data: {
		emailPopUpShown: null
	},
	steps: initialEmailSteps
}

export interface PensionGoalData {
	netAmount: number
	pensiongoalPercentage: number
	pensionProfileProgress?: number
	pensionExpectedAmount?: number
	pensionGapPercentage?: number
}

export interface VariablePensionData {
	profile: "fixed" | "variable" | undefined
}

export interface ValuetransferData {
	name?: string
}

export interface ExtraSavingsData {
	eventType: string
	date: number
	name: string,
	gender: string,
	employerName: string,
	participationNumber: number
	email: string
	emailEmployer: string | null
	emailIntermediary: string | null
	currentAccount: string | null
	additionalSavingsAmount?: number,
	frequency?: Term,
	additionalSavingsDate: number
}

export interface RiskProfileQuestions {
	hasPartner?: boolean,
	partnerSaves?: boolean,
	earnsMore?: string,
	rentsHouse?: string,
	mortgageDone?: boolean,
	preferredRisk?: string,
	financialRisk?: string,
	profilePicked?: string
}

export interface RiskProfileData extends RiskProfileQuestions {
	pensiongoal: number,
	otherIncomeItems: OtherIncomeItems
}

export interface RiskProfileForCalculation extends RiskProfileQuestions {
	noOtherIncome?: boolean | undefined,
	otherEmployers?: boolean | undefined,
	savings?: boolean | undefined,
	inheritance?: boolean | undefined,
	annuity?: boolean | undefined,
	other?: boolean | undefined,
	pensionGap?: number | undefined
}

export interface OtherIncomeItems {
	noOtherIncome: boolean,
	otherEmployers: boolean,
	savings: boolean,
	inheritance: boolean,
	annuity: boolean,
	other: boolean
}

// 1 / 0: Pension Profile always starts at 20%, so created dummystep which immediately ends
// 2 / 1: CJ Risicoprofiel is gestart, deelnemer heeft alleen de vragen bij Leefsituatie ingevuld.
// 3 / 2: CJ Risicoprofiel is gestart, deelnemer heeft alleen de vragen bij Inkomsten ingevuld.
// 4 / 3: CJ Risicoprofiel is afgerond.
// 5 / 4: CJ Vast of Variabel is afgerond.
// 6 / 5: Uitgavencheck/ Nibud
// 7 / 6: Pensioenoverzicht geupload (Deze stap telt mee voor Pensioenprofiel op dashboard. Stap 7 OF 8 (overig inkomen) telt
// mee voor pensioenprofiel op dashboard. Ze tellen nooit allebei mee.
// Deze stap gebruiken we ook voor Highlights NPR nog uploaden of NPR ouder dan 12 maanden).
// 8 / 7: Overig inkomen toegevoegd (Deze stap telt mee voor Pensioenprofiel op dashboard. Stap 7 OF 8 (overig inkomen) telt
// mee voor pensioenprofiel op dashboard. Ze tellen nooit allebei mee).
// 9 / 8: Check dat pensioendoel is gezet in de CJ Risicoprofiel of via conversie.
// Het bedrag dat gezet is in de CJ Risicoprofiel of via conversie moet wel overal gebruikt worden,
// maar dat pensioendoel gezet is in de CJ Risicoprofiel of via conversie mag niet meetellen voor het pensioenprofiel.
// Voor dit doel dummystap 8 in het leven geroepen.
const initialPensionProfileSteps: Array<StateStep> = [
	{ step: "1", weight: 20, startDateTime: new Date(), endDateTime: new Date() },
	{ step: "2", weight: 10 },
	{ step: "3", weight: 10 },
	{ step: "4", weight: 15 },
	{ step: "5", weight: 15 },
	{ step: "6", weight: 10 },
	{ step: "7", weight: 20 },
	{ step: "8", weight: 20 },
	{ step: "9", weight: 0 }
]

export const initialPensionProfileState: PensionProfilePayload = {
	key: "PENSION_PROFILE",
	version: "1.0",
	status: "PENDING", // always pending, looking at the steps for substatus
	startDateTime: new Date(),
	data: {
		netAmount: 0,
		pensiongoalPercentage: -1,
		pensionProfileProgress: 0,
		pensionExpectedAmount: 0,
		pensionGapPercentage: 0
	},
	steps: initialPensionProfileSteps
}

const initialOtherIncomeItems: OtherIncomeItems = {
	noOtherIncome: false,
	otherEmployers: false,
	savings: false,
	inheritance: false,
	annuity: false,
	other: false
}

export const initialRiskProfileAnswers: RiskProfileData = {
	hasPartner: undefined,
	partnerSaves: undefined,
	earnsMore: undefined,
	rentsHouse: undefined,
	mortgageDone: undefined,
	pensiongoal: 0,
	preferredRisk: undefined,
	financialRisk: undefined,
	profilePicked: undefined,
	otherIncomeItems: initialOtherIncomeItems
}

export const initialValuetransferData: ValuetransferData = {
	name: ""
}

const initialRiskProfileSteps: Array<StateStep> = [
	{ step: "1", weight: 0 },
	{ step: "2", weight: 0 },
	{ step: "3", weight: 0 },
	{ step: "4", weight: 0 },
	{ step: "5", weight: 0 }
]

export const initialRiskProfileState: RiskProfilePayload = {
	key: "CJ_RISKPROFILE",
	version: "1.0",
	status: "STARTED",
	startDateTime: new Date(),
	data: initialRiskProfileAnswers,
	steps: initialRiskProfileSteps
}

const initialVariablePensionSteps: Array<StateStep> = [
	{ step: "1", weight: 0 },
	{ step: "2", weight: 0 },
	{ step: "3", weight: 0 }
]

export const initialVariablePensionState: VariablePensionPayload = {
	key: "CJ_VARIABLEPENSION",
	version: "1.0",
	status: "STARTED",
	startDateTime: new Date(),
	data: { profile: undefined },
	steps: initialVariablePensionSteps
}

const initialValuetransferSteps: Array<StateStep> = [
	{ step: "1", weight: 0 },
	{ step: "2", weight: 0 },
	{ step: "3", weight: 0 }
]

export const initialValueTransferState: StatePayload = {
	key: "CJ_VALUETRANSFER",
	version: "1.0",
	status: "STARTED",
	startDateTime: new Date(),
	steps: initialValuetransferSteps
}

const initialExtraSavingsSteps: Array<StateStep> = [
	{ step: "1", weight: 0 },
	{ step: "2", weight: 0 },
	{ step: "3", weight: 0 }
]

export const initialExtraSavingsState: StatePayload = {
	key: "CJ_EXTRASAVINGS",
	version: "1.0",
	status: "STARTED",
	startDateTime: new Date(),
	steps: initialExtraSavingsSteps
}
