import Vue from "vue"
import Vuex, {MutationTree, Store} from "vuex"
import persist from "lib/vuex/plugins/persist"

import {cloneDeep} from "lodash-es"
import {storageOptions, storageController} from "lib/storage/session"
import messages from "./messages"
import customerJourneys from "./customerJourneys"
import {RootState, Locale} from "./types"
import load from "./load"

import pensiongoal from "./pensiongoal"
import pensiondetails from "./pensiondetails"
import riskprofile from "./riskprofile"
import variablepension from "./variablepension"
import participant from "./participant"
import policyholder from "./policyholder"
import dossier from "./dossier"
import stateModule from "./state"
import eventBus from "lib/vue/eventBus"
import {IDENTIFICATION_FAILED} from "lib/vue/events"

Vue.use(Vuex)

const rootState: RootState = {
	timestamp: 0,
	lastLogin: null,
	active: false,
	logout: false,
	locale: "nl-NL"
}

const mutations: MutationTree<RootState> = {
	timestamp(state) {
		state.timestamp = Date.now()
	},
	logout(state) {
		state.logout = true
		state.active = false
	},
	login(state) {
		state.active = true
		state.logout = false
	},
	locale(state, locale: Locale) {
		state.locale = locale
	}
}

const plugins = process.env.SERVER ? [] : [
	persist({
		...storageOptions,
		blacklist: ["text"],
		deactivate: s => s.state.logout,
		empty: s => !s.state.timestamp
	})
]

const store = new Store<RootState>({
	modules: {
		messages,
		customerJourneys,
		pensiondetails,
		pensiongoal,
		riskprofile,
		variablepension,
		participant,
		policyholder,
		dossier,
		progressState: stateModule
	},
	actions: {
		async login(context) {
			if (await load(this)) {
				context.commit("login")
				context.commit("timestamp")
				return true
			}
			if (storageController.retrieve("confidential") === "SENSITIVE_CONTRACT") {
				eventBus.emit("confidential", { reason: "SENSITIVE_CONTRACT" })
			} else {
				eventBus.emit(IDENTIFICATION_FAILED, { reason: "UNKNOWN_USER" })
			}
			return false
		}
	},
	mutations,
	plugins,
	state: cloneDeep(rootState),
	strict: process.env.NODE_ENV !== "production"
})

export default store
