import {AuthorizationStrategy, IdentificationStrategy} from "lib/types/security"
import eventBus from "lib/vue/eventBus"
import {ACCESS_DENIED, ACCESS_RESTORED, AUTHORIZATION_FAILED, ACCESS_GRANTED, IDENTIFICATION_FAILED, ACCESS_REVOKED} from "lib/vue/events"
import HttpStatus from "lib/request/status"
import RequestError from "lib/request/RequestError"
import {throttle} from "lodash-es"

export default class Session<C> {

	constructor(
		private readonly identification: IdentificationStrategy<C>,
		private readonly authorization: AuthorizationStrategy,
		private readonly timeout: number = Infinity
	) {
	}

	/**
	 * Verifies that the user is authenticated for the roles.
	 */
	verify(roles?: ReadonlyArray<string>): void {
		if (this.authorization.isAuthorized && this.identification.isIdentified) {
			if (roles && roles.length && !this.authorization.isAuthorizedAny(roles)) {
				eventBus.emit(ACCESS_DENIED)
			} else {
				eventBus.emit(ACCESS_RESTORED)
			}
		} else {
			eventBus.emit(AUTHORIZATION_FAILED)
		}
		if (this.timeout < Infinity) {
			this.watch()
		}
	}

	async login(credentials: C): Promise<void> {
		try {
			const token = await this.identification.identify(credentials)
			const success = this.authorization.authorize(token)
			if (success) {
				eventBus.emit(ACCESS_GRANTED, this.identification.identifier)
			} else {
				eventBus.emit(ACCESS_DENIED)
			}
		} catch (error) {
			if (error instanceof RequestError) {
				const reason = await error.response.json().catch(() => error.response.text())
				eventBus.emit(
					error.response.status === HttpStatus.FORBIDDEN ? ACCESS_DENIED : IDENTIFICATION_FAILED,
					reason
				)
			} else {
				eventBus.emit(IDENTIFICATION_FAILED)
			}
		}
	}

	impersonate(identifier: string | undefined, token: unknown): boolean {
		if (this.authorization.authorize(token)) {
			this.identification.identifier = identifier
			return true
		}
		return false
	}

	async logout(): Promise<void> {
		if (this.authorization.isAuthorized) {
			const unidentified = await this.identification.unidentify()
			if (unidentified) {
				this.authorization.unauthorize()
				eventBus.emit(ACCESS_REVOKED)
			}
		}
	}

	/**
	 * Forgets the identity of the user, but keeps the token. After this, the session is no longer valid
	 * but secured calls to the backend are still possible. This can be useful if the user is unknown.
	 */
	async forget(): Promise<void> {
		await this.identification.unidentify()
	}

	private watch(): void {
		if (!process.env.SERVER) {
			const start = () => setTimeout(async () => {
				await this.logout()
			}, this.timeout * 60 * 1000)
			let timer = start()

			const reset = throttle((event: any) => { // Duck type event, because the Event interface does not have altKey etc.
				if (!event.altKey && !event.ctrlKey && !event.metaKey) {
					clearTimeout(timer)
					timer = start()
				}
			}, 1000, {leading: true})
			for (const event of ["mousedown", "keydown", "touchstart"]) {
				document.body.addEventListener(event, reset, {capture: true})
			}
		}
	}

}
