import {Options} from "lib/types/i18n"
import {Function1} from "lib/types/function"
import Vue from "vue"
import i18n from "lib/i18n/install"
import {StorageOptions} from "lib/types/storage"

/**
 * A function that loads the locale using a dynamic import. The object returned
 * will contain the locale under the `default` key.
 */
type Load = Function1<string, Promise<{default: Options}>>

const DEFAULT_KEY = "locale"

/**
 * Takes a `StorageOptions` and returns a function that fetches and saves the locale in storage.
 */
export const save = (options: StorageOptions) => async (load: Load, locale: string): Promise<boolean> => {
	const i18nOptions = await load(locale)

	options.storage.store(options.key || DEFAULT_KEY, i18nOptions.default)
	return true
}

/**
 * Takes a `StorageOptions` and returns a function that reads a previously saved locale from session storage and applies it.
 */
export const apply = (options: StorageOptions) => (): boolean => {
	const i18nOptions = options.storage.retrieve(options.key || DEFAULT_KEY)
	if (i18nOptions) {
		Vue.use(i18n, i18nOptions)
		return true
	}
	return false
}
