import { isUndefined } from "lodash"

export enum AuthenticationMethod {
	IMPERSONATE = "IMPERSONATE",
	DIGID = "DIGID",
	EIDAS = "EIDAS"
}

export class AuthenticationStorage {
	private static readonly AUTHENTICATION_METHOD_KEY = "AUTHENTICATION_METHOD"

	get authenticationMethod(): AuthenticationMethod | undefined {
		if (process.env.SERVER) {
			return
		}

		return localStorage.getItem(AuthenticationStorage.AUTHENTICATION_METHOD_KEY) as AuthenticationMethod
	}

	set authenticationMethod(authenticationMethod: AuthenticationMethod | undefined) {
		if (!isUndefined(authenticationMethod)) {
			localStorage.setItem(AuthenticationStorage.AUTHENTICATION_METHOD_KEY, authenticationMethod)
		} else {
			localStorage.removeItem(AuthenticationStorage.AUTHENTICATION_METHOD_KEY)
		}
	}
}

export const authenticationStorage = new AuthenticationStorage()
