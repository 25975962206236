import {RootState} from "../types"
import {Variablepension,
		VariablePensionShortCodes,
		variablePensionsDEF,
		variablePensionsNEU,
		variablePensionsOFF
	} from "./types"
import {Module, GetterTree} from "vuex"
import {Profiles, RiskProfileCharts} from "store/riskprofile/types"
import {AllianzPolicy,
		RiskProfileToCalculate,
		RiskProfileShortCodes,
		CapitalInfo
	} from "store/pensiondetails/types"
import store from "../index"
import formatISO from "lib/date/formatISO"

const getters: GetterTree<Variablepension, any> = {
	variablePensions: state => state.variablePensions,
	profiles: state => state.profiles
}

interface CalculatedCapital {
	pessimistic: number
	expected: number
	optimistic: number
}

const variablepensionState: Variablepension = {
	profiles: {
		fixed: { pessimistic: 0, expected: 0, optimistic: 0 },
		variable: { pessimistic: 0, expected: 0, optimistic: 0 }
	},
	variablePensions: {
		["DEF"]: {
			["VAST_100"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_75"]: { pessimistic: 0, expected: 0, optimistic: 0 }
		},
		["NEU"]: {
			["VAST_100"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_75"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_50"]: { pessimistic: 0, expected: 0, optimistic: 0 }
		},
		["OFF"]: {
			["VAST_100"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_75"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_50"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_25"]: { pessimistic: 0, expected: 0, optimistic: 0 },
			["VAST_0"]: { pessimistic: 0, expected: 0, optimistic: 0 }
		}
	},
	policyVariableCombinationLoaded: [],
	variablePensionChartsLoaded: false
}

const variablepension: Module<Variablepension, RootState> = {
	actions: {
		async loadVariablePensionCharts(state, riskProfile: Profiles) {
			// SET VAST100: Is already a total amount loaded in Pensiondetails while loading RiskCharts
			const variablePensionCapitalsVast100 = state.rootState.pensiondetails!.riskProfileForVariableCharts[riskProfile]

			state.commit("setVast100", {riskProfileShortCode: riskProfile,
				variableProfileCode: "VAST_100", capitals: variablePensionCapitalsVast100})

			// LOAD 100% VARIABLE OPTION // ALWAYS ENDS WITH _DB_PAS
			const policies: Array<AllianzPolicy> = state.rootGetters["pensiondetails/policiesWithMoreThan10RProfiles"]

			const calculatedCapitalVast0: CalculatedCapital = {
				pessimistic: 0,
				expected: 0,
				optimistic: 0
			}

			if (!state.state.variablePensionChartsLoaded) {
				for (const policy of policies) {

					let loadCapital = true

					if (policy.status === "Active" && policy.currentPremium === 0) {
						loadCapital = false
					}

					if (loadCapital) {
						let riskProfileKey = await store.dispatch("pensiondetails/getRiskProfileKeyByPolicyAndName", {policy,
							riskProfileNewCode: `${policy.product}_DB_PAS`})
						if (riskProfileKey === "") {
							riskProfileKey = await store.dispatch("pensiondetails/getRiskProfileKeyByPolicyAndName", {policy,
							riskProfileNewCode: `${policy.product}_MAN_DB`})
						}
						const riskProfileToCalculate: RiskProfileToCalculate = {
							policyId: policy.id,
							pensionDate: formatISO(policy.pensioenDatum),
							riskProfileKey
						}

						// Calculating 100% doorbeleggen here. Once for al riskprofiles: DEF/ NEU and OFF
						const variablePensionCapitalsVast0: CapitalInfo = await store.dispatch("pensiondetails/loadRiskProfileCapitals", riskProfileToCalculate)

						if (variablePensionCapitalsVast0) {
							calculatedCapitalVast0.pessimistic = calculatedCapitalVast0.pessimistic + variablePensionCapitalsVast0.capitals.pessimisticRealAnnuity,
							calculatedCapitalVast0.expected = calculatedCapitalVast0.expected + variablePensionCapitalsVast0.capitals.expectedRealAnnuity,
							calculatedCapitalVast0.optimistic = calculatedCapitalVast0.optimistic + variablePensionCapitalsVast0.capitals.optimisticRealAnnuity
						}
					}
				}

				state.commit("setVast0", {riskProfileShortCode: "OFF",
					variableProfileCode: "VAST_0", capitals: calculatedCapitalVast0})

				state.commit("setVariablePensionChartsLoaded", true)
			}

			let remainingVariableProfileCodes = []

			if (riskProfile === "DEF") {
				remainingVariableProfileCodes = variablePensionsDEF
			} else if (riskProfile === "NEU") {
				remainingVariableProfileCodes = variablePensionsNEU
			} else {
				remainingVariableProfileCodes = variablePensionsOFF
			}

			for (const variableProfileCode of remainingVariableProfileCodes) {
				if (variableProfileCode !== "VAST_100") {
					const vastPercentage = Number(variableProfileCode.split("_")[1])
					const variablePercentage = 100 - vastPercentage

					const calculatedCapital: CalculatedCapital = {
						pessimistic: 0,
						expected: 0,
						optimistic: 0
					}
					const vast0Key = "OFF"

					calculatedCapital.pessimistic = state.state.variablePensions[riskProfile].VAST_100.pessimistic * vastPercentage / 100
													+ state.state.variablePensions[vast0Key].VAST_0.pessimistic * variablePercentage / 100

					calculatedCapital.expected = state.state.variablePensions[riskProfile].VAST_100.expected * vastPercentage / 100
													+ state.state.variablePensions[vast0Key].VAST_0.expected * variablePercentage / 100

					calculatedCapital.optimistic = state.state.variablePensions[riskProfile].VAST_100.optimistic * vastPercentage / 100
					+ state.state.variablePensions[vast0Key].VAST_0.optimistic * variablePercentage / 100

					state.commit("sumVariablePensionCharts", {riskProfileShortCode: riskProfile,
					variableProfileCode, capitals: calculatedCapital})

				}
			}

		}
	},
	getters,
	mutations: {
		setVariablePensionChartsLoaded(state, value: boolean) {
			state.variablePensionChartsLoaded = value
		},
		setVast100(state, data: {policyId: string, riskProfileShortCode: RiskProfileShortCodes,
			variableProfileCode: "VAST_100", capitals: RiskProfileCharts["DEF"] | RiskProfileCharts["NEU"] | RiskProfileCharts["OFF"]}) {
				state.variablePensions[data.riskProfileShortCode][data.variableProfileCode].pessimistic = data.capitals.pessimistic
				state.variablePensions[data.riskProfileShortCode][data.variableProfileCode].expected = data.capitals.expected
				state.variablePensions[data.riskProfileShortCode][data.variableProfileCode].optimistic = data.capitals.optimistic
		},
		setVast0(state, data: {policyId: string, riskProfileShortCode: "OFF",
			variableProfileCode: "VAST_0", capitals: RiskProfileCharts["OFF"]}) {
				state.variablePensions[data.riskProfileShortCode][data.variableProfileCode].pessimistic = data.capitals.pessimistic
				state.variablePensions[data.riskProfileShortCode][data.variableProfileCode].expected = data.capitals.expected
				state.variablePensions[data.riskProfileShortCode][data.variableProfileCode].optimistic = data.capitals.optimistic
		},
		sumVariablePensionCharts(state, data: {policyId: string, riskProfileShortCode: RiskProfileShortCodes,
			variableProfileCode: VariablePensionShortCodes, capitals: CalculatedCapital}) {

			const policyVariableCombinationLoaded = state.policyVariableCombinationLoaded.findIndex(combination =>
				combination.riskProfileShortCode === data.riskProfileShortCode &&
				combination.variableProfileCode === data.variableProfileCode)

			if (policyVariableCombinationLoaded === -1) {
				if (data.riskProfileShortCode === "DEF") {
					if (data.variableProfileCode === "VAST_100" || data.variableProfileCode === "VAST_75") {
						const profileData = state.variablePensions[data.riskProfileShortCode][data.variableProfileCode]
						profileData.pessimistic += data.capitals.pessimistic
						profileData.expected += data.capitals.expected
						profileData.optimistic += data.capitals.optimistic
					}
				} else if (data.riskProfileShortCode === "NEU") {
					if (data.variableProfileCode === "VAST_100" || data.variableProfileCode === "VAST_75" || data.variableProfileCode === "VAST_50") {
						const profileData = state.variablePensions[data.riskProfileShortCode][data.variableProfileCode]
						profileData.pessimistic += data.capitals.pessimistic
						profileData.expected += data.capitals.expected
						profileData.optimistic += data.capitals.optimistic
					}
				} else {
					const profileData = state.variablePensions[data.riskProfileShortCode][data.variableProfileCode]
					profileData.pessimistic += data.capitals.pessimistic
					profileData.expected += data.capitals.expected
					profileData.optimistic += data.capitals.optimistic
				}

				state.policyVariableCombinationLoaded.push({
															riskProfileShortCode: data.riskProfileShortCode,
															variableProfileCode: data.variableProfileCode})
			}
		}
	},
	namespaced: true,
	state: variablepensionState
}

export default variablepension
