import RequestStrategy from "./RequestStrategy"
import {AuthorizationStrategy} from "lib/types/security"
import {RequestMethod, Parameters} from "lib/types/request"
import eventBus from "lib/vue/eventBus"
import {AUTHORIZATION_FAILED, ACCESS_DENIED} from "lib/vue/events"
import HttpStatus from "lib/request/status"

export default class AuthorizedGateway implements RequestStrategy {

	constructor(private readonly authorization: AuthorizationStrategy) {}

	async request(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		const response = await this.authorization.request(method, input, data)
		switch (response.status) {
			case HttpStatus.UNAUTHORIZED:
				eventBus.emit(AUTHORIZATION_FAILED)
				break
			case HttpStatus.FORBIDDEN:
				eventBus.emit(ACCESS_DENIED)
				break
		}

		return response
	}

}
