import Vue from "vue"

import { isUndefined } from "lodash-es"

export enum ToastType {
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
	WARNING = "WARNING",
	INFO = "INFO",
	CONFIRM = "CONFIRM"
}

export interface Toast {
	id?: string
	title: string
	type: ToastType
	durationMs: number | null
	onClick?: () => void
	onDismiss?: () => void
}

export interface ConfirmationToast extends Toast {
	confirmationMessage: string
	rejectionMessage: string
}

export interface ToastConfig {
	id?: string
	title: string
	durationMs?: number | null
	onClick?: () => void
	onDismiss?: () => void
}

export interface ConfirmationToastConfig extends ToastConfig {
	confirmationMessage?: string
	rejectionMessage?: string
}

export const SHOW_TOAST = "SHOW_TOAST"
export const CONFIRM_TOAST = "CONFIRM_TOAST"
export const DISMISS_TOAST = "DISMISS_TOAST"
export const eventBus = new Vue()

export default class Toaster {
	private static readonly DEFAULT_DURATION = 3000
	private static readonly DEFAULT_CONFIRM_DURATION = 5000

	createToast(config: ToastConfig, type: ToastType): Toast {
		return {
			id: config.id,
			title: config.title,
			type,
			durationMs: isUndefined(config.durationMs) ? Toaster.DEFAULT_DURATION : config.durationMs,
			onClick: config.onClick,
			onDismiss: config.onDismiss
		}
	}

	success(config: ToastConfig): void {
		const toast = this.createToast(config, ToastType.SUCCESS)
		eventBus.$emit(SHOW_TOAST, toast)
	}

	error(config: ToastConfig): void {
		const toast = this.createToast(config, ToastType.ERROR)
		eventBus.$emit(SHOW_TOAST, toast)
	}

	warning(config: ToastConfig): void {
		const toast = this.createToast(config, ToastType.WARNING)
		eventBus.$emit(SHOW_TOAST, toast)
	}

	info(config: ToastConfig): void {
		const toast = this.createToast(config, ToastType.INFO)
		eventBus.$emit(SHOW_TOAST, toast)
	}

	confirm(config: ConfirmationToastConfig): Promise<boolean> {
		return new Promise(resolve => {
			const toast: ConfirmationToast = {
				title: config.title,
				type: ToastType.CONFIRM,
				durationMs: config.durationMs ?? Toaster.DEFAULT_CONFIRM_DURATION,
				confirmationMessage: config.confirmationMessage ?? "general.toast.confirm.confirm",
				rejectionMessage: config.rejectionMessage ?? "general.toast.confirm.reject"
			}

			eventBus.$once(CONFIRM_TOAST, (confirmed: boolean) => resolve(confirmed))
			eventBus.$emit(SHOW_TOAST, toast)
		})
	}
}

export const toaster = new Toaster()
