import {ObjectRules, Datatype} from "lib/types/validation"
import {ChoicesData} from "custom/planner/types"
import objectValidator from "lib/validation/objectValidator"
import {Policyholder} from "api/models/policyholder"
import diff, {Diff} from "lib/date/diff"

const contextRules: ObjectRules<ChoicesData, Policyholder> = {
	parttime: {
		type: Datatype.BOOLEAN,
		required: true
	},
	extra: {
		type: Datatype.BOOLEAN,
		required: true
	},
	workAgeYears: {
		type: Datatype.NUMBER,
		required() {
			return !!this.parttime
		},
		minimum: 0,
		custom: {
			future(workAgeYears, policyholder) {
				const age = diff(Diff.MONTHS, policyholder.personal.birthdate, new Date())
				const workAge = workAgeYears * 12 + (this.workAgeMonths || 0)
				return workAge > age
			},
			ltPensionAge(workAgeYears) {
				if (workAgeYears && this.pensionAgeYears) {
					const pensionAge = this.pensionAgeYears + (this.pensionAgeMonths || 0) / 12
					const workAge = workAgeYears + (this.workAgeMonths || 0) / 12
					return workAge <= pensionAge
				}
				return true
			}
		}
	},
	workAgeMonths: {
		type: Datatype.NUMBER,
		required: false,
		minimum: 0,
		maximum: 11
	},
	workHours: {
		type: Datatype.NUMBER,
		required() {
			return !!this.parttime
		},
		minimum: 1,
		custom: {
			lteWorkHoursFull(workHours) {
				return !!this.workHoursFull && this.workHoursFull >= workHours
			}
		}
	},
	workHoursFull: {
		type: Datatype.NUMBER,
		required() {
			return !!this.parttime
		},
		minimum: 1,
		maximum: 40
	},
	pensionAgeYears: {
		type: Datatype.NUMBER,
		required: true,
		minimum: 55,
		maximum: 73,
		custom: {
			future(pensionAgeYears, policyholder) {
				const age = diff(Diff.MONTHS, policyholder.personal.birthdate, new Date())
				const pensionAge = pensionAgeYears * 12 + (this.pensionAgeMonths || 0)
				return pensionAge > age
			},
			gtWorkAge(pensionAgeYears) {
				if (pensionAgeYears && this.workAgeYears) {
					const pensionAge = pensionAgeYears * 12 + (this.pensionAgeMonths || 0)
					const workAge = this.workAgeYears * 12 + (this.workAgeMonths || 0)
					return pensionAge >= workAge
				}
				return true
			}
		}
	},
	pensionAgeMonths: {
		type: Datatype.NUMBER,
		required: false,
		minimum: 0,
		maximum: 11,
		custom: {
			lteMaxPensionAge(pensionAgeMonths) {
				return this.pensionAgeYears && this.pensionAgeYears === contextRules.pensionAgeYears!.maximum ? pensionAgeMonths === 0 : true
			}
		}
	},
	partnerAmount: {
		type: Datatype.NUMBER,
		required: false,
		minimum: 0
	},
	extraAmount: {
		type: Datatype.NUMBER,
		required() {
			return !!this.extra
		},
		custom: {
			ltMinExtraAmount(extraAmount) {
				return extraAmount > 0
			},
			gtMaxExtraAmountOnce(extraAmount, policyholder) {
				if (this.extraTerm === "ONCE") {
					const maxExtraAmountOnce = Math.round(policyholder.fiscalRoomOneTime)
					return extraAmount <= maxExtraAmountOnce
				} else {
					return true
				}
			},
			gtMaxExtraAmountYearly(extraAmount, policyholder) {
				if (this.extraTerm === "YEARLY") {
					const maxExtraAmountYearly = Math.round(policyholder.fiscalRoomYearly)
					return extraAmount <= maxExtraAmountYearly
				} else {
					return true
				}
			},
			gtMaxExtraAmountMonthly(extraAmount, policyholder) {
				if (this.extraTerm === "MONTHLY") {
					const maxExtraAmountYearly = Math.round(policyholder.fiscalRoomYearly)
					const maxExtraAmountMonthly = Math.round(maxExtraAmountYearly / 12)
					return extraAmount <= maxExtraAmountMonthly
				} else {
					return true
				}
			}
		}
	},
	extraTerm: {
		type: Datatype.STRING,
		required() {
			return !!this.extra
		}
	}
}

export default objectValidator(contextRules)
