import {IdentificationStrategy, SamlArt, SamlResponse} from "lib/types/security"
import RequestStrategy from "lib/request/RequestStrategy"
import CredentialsIdentification from "./CredentialsIdentification"
import { StorageOptions } from "lib/types/storage"

export default class SamlIdentification implements IdentificationStrategy<null> {
	private readonly identification: CredentialsIdentification<SamlArt, SamlResponse>

	constructor(
		options: StorageOptions,
		private readonly gateway: RequestStrategy,
		tokenEndpoint: string,
		private readonly logoutUrlEndpoint?: string,
		verifyLogoutEndpoint?: string
	) {
		this.identification = new CredentialsIdentification(options, gateway, tokenEndpoint, verifyLogoutEndpoint)
	}

	async identify(): Promise<any> {
		const samlArt = new URL(location.href).searchParams.get("SAMLart") // The parameter name is case sensitive.
		if (!samlArt) {
			throw new ReferenceError("Parameter samlArt does not exist")
		}
		return this.identification.identify({samlArt})
	}

	get isIdentified(): boolean {
		return this.identification.isIdentified
	}

	async unidentify(): Promise<boolean> {
		const samlResponse = new URL(location.href).searchParams.get("SAMLResponse")
		if (!samlResponse) {
			if (this.logoutUrlEndpoint) {
				const response = await this.gateway.request("GET", this.logoutUrlEndpoint)
				location.href = await response.text()
				return false
			} else {
				return this.identification.unidentify()
			}
		}
		return this.identification.unidentify({samlResponse})
	}

	get identifier(): string | undefined {
		return this.identification.identifier
	}

	set identifier(identifier: string | undefined) {
		this.identification.identifier = identifier
	}

}
