import {get} from "lodash-es"
import Vue from "vue"

interface Context {
	[key: string]: any
}

/**
 * Renders the mustache and applies Vue filters.
 *
 * @argument {string} mustache The contents of the mustache to be rendered.
 * @argument {object} context The context to get values from.
 * @returns {string}
 */
const render = (mustache: string, context: Context): string => {
	const pipeline = mustache.split("|").map(s => s.trim())
	if (pipeline.length === 0) {
		throw new RangeError("Empty mustache")
	}
	const value = get(context, pipeline[0]) ?? `{{ ${pipeline[0]}: undefined }}`
	if (pipeline.length === 1) {
		return value.toString()
	}

	// Process the filters. At this point, only filters without arguments are supported.
	return pipeline.slice(1).reduce((result, filter) => Vue.filter(filter)(result), value)
}

export default (template: string, context: Context) => {
	// 'Grammar' for mustaches: {{ variable [ | variable ]* }}
	const matcher = /{{(([ ]?[A-Z0-9\.]+[ ]?[|]?)*)}}/gi
	const mustaches = [...template.matchAll(matcher)]
	// Every result is an array where element 0 is the complete match, and 1 is the contents within {{ and }}.
	return mustaches.reverse().reduce((content, [match, contents]) => content.replace(match, render(contents, context)), template)
}
