import {ObjectRules, FieldValidator, FieldRules, FieldErrors, ScalarErrors, ObjectValidator, Datatype, ObjectErrors, Validator} from "lib/types/validation"
import {isEmpty, isNil, toString, isUndefined, isFunction, isArray, isPlainObject} from "lodash-es"
import {datetime, alphanumeric, numeric, bool, file} from "lib/import/convert"
import {ConversionFunction} from "lib/types/import"
import entries from "lib/misc/entries"

export const ruleTypes: ReadonlyArray<keyof FieldRules<keyof any, any, undefined>> = ["minlength", "maxlength", "minimum", "maximum", "pattern", "values", "custom", "fields"]

const typeConverters = new Map<Datatype, ConversionFunction<any>>([
	[Datatype.BOOLEAN, bool],
	[Datatype.DATE, datetime],
	[Datatype.NUMBER, numeric],
	[Datatype.STRING, alphanumeric],
	[Datatype.FILE, file],
	[Datatype.ARRAY, isArray],
	[Datatype.OBJECT, isPlainObject]
])
const converter = (parameter: Datatype) => typeConverters.get(parameter)!

const values = <T>(parameter: Set<T>) => (value: T) => parameter.has(value) // TODO: does not work for dates.
// String:
const maxlength = (parameter: number) => (value: string) => value.length <= parameter
const minlength = (parameter: number) => (value: string) => value.length >= parameter
const pattern = (parameter: RegExp) => (value: string) => parameter.test(value)
// Number or Date:
const maximum = <T extends number | Date>(parameter: T) => (value: T) => value <= parameter
const minimum = <T extends number | Date>(parameter: T) => (value: T) => value >= parameter

/**
 * Returns the array of field validator functions based on the rules defined for the field.
 */
const fieldValidators = <F extends keyof T, T, C>(rules: FieldRules<F, T, C>): ReadonlyArray<FieldValidator<F, T, C>> =>
	ruleTypes.filter(it => !isNil(rules[it])).map(
		rule => {
			switch (rule) {
				case "fields": {
					const validate = objectValidator(rules.fields!)
					return function (this: Partial<T>, value: NonNullable<T[F]>, context: C): ObjectErrors<T[F]> {
						return validate.call(this, value, context)
					}
				}
				case "custom":
					return customValidator(rules.custom!)
				default: {
					const validator: Validator<any, T, C> | undefined =
						rule === "pattern" ? pattern(rules.pattern!) :
						rule === "maxlength" ? maxlength(rules.maxlength!) :
						rule === "minlength" ? minlength(rules.minlength!) :
						rule === "maximum" ? maximum(rules.maximum as number | Date) :
						rule === "minimum" ? minimum(rules.minimum as number | Date) :
						rule === "values" ? values(new Set(rules.values!)) :
						undefined

					if (process.env.NODE_ENV === "development") {
						// The next check will drop out of the build due to tree shaking but is
						// performed during development.
						if (!validator) {
							throw new TypeError(`Unsupported rule ${rule}`)
						}
					}

					return scalarFieldValidator(rule, validator!)
				}
			}
		}
	)

/**
 * Returns a field validator that performs the given validation rule for the scalar field. It returns an array of 0 or 1 failed rules.
 */
const scalarFieldValidator = <F extends keyof T, T, C>(rule: string, validate: Validator<T[F], T, C>) =>
	function (this: Partial<T>, value: NonNullable<T[F]>, context: C): ScalarErrors {
		return validate.call(this, value, context) ? [] : [rule]
	}

/**
 * Returns a field validator that performs the defined custom rules for the scalar field. It returns an array of 0 or more failed rules.
 */
const customValidator = <F extends keyof T, T, C>(validations: NonNullable<FieldRules<F, T, C>["custom"]>) => {
	// The custom object contains one or more rules. Create a function that runs all of them and returns the results as
	// a single array of failed rules.
	const validators = Object.entries(validations).map(
		([rule, validate]) => scalarFieldValidator(rule, validate as Validator<T[F], T, C>) // TODO: find out why cast is needed.
	)
	return function (this: Partial<T>, value: NonNullable<T[F]>, context: C): ScalarErrors {
		return validators.flatMap(validate => validate.call(this, value, context))
	}
}

/**
 * Returns a context validator that performs the validation of a context against the given set of rules.
 */
const objectValidator = <T, C>(contextRules: ObjectRules<T, C>): ObjectValidator<T, C> => {
	const validatorMap = new Map<keyof T, FieldValidator<keyof T, T, C>>()

	for (const [field, rules] of entries(contextRules)) {
		// For all defined rules, create a field validator.
		const validators = fieldValidators(rules)
		const convert = converter(rules.type)
		const fieldValidator = function (this: Partial<T>, value: unknown, context: C): FieldErrors {
			const required = isFunction(rules.required) ? rules.required.call(this, context) : rules.required || false
			if (isNil(value) || isEmpty(toString(value))) {
				// Validation is successful if the field is not required.
				return required ? "required" : undefined
			}
			// Convert the value. This will return undefined if the value is not of the proper type.
			const convertedValue = convert(value)
			if (isUndefined(convertedValue)) {
				return "type"
			}

			// Run the validations. At this point, the value is defined and of the proper type.
			const results = validators.map(validate => validate.call(this, convertedValue, context))
			// Results is an array of field errors. If there is one array element, return that, or else flatten the array.
			// Flattening the array is only useful for scalar errors (which is an array of strings). The other error types are
			// maps or objects (of which there is at most one).
			return results.length === 0 ? undefined : results.length === 1 ? results[0] : results.flat()
		}
		validatorMap.set(field, fieldValidator)
	}

	// Create the actual validator function for the object.
	return function (this: void, object: any, context: C) {
		const objectErrors: ObjectErrors<T> = {}
		for (const [field, validate] of validatorMap) {
			const errors = validate.call(object, object[field], context)
			if (!isEmpty(errors)) {
				objectErrors[field] = errors
			}
		}
		return isEmpty(objectErrors) ? undefined : objectErrors
	}
}

export default objectValidator
