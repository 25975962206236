import {ConversionFunction} from "lib/types/import"
import {isNil, isEmpty, toNumber, isArray, isBoolean, isNumber, isDate, isString, isUndefined, toString} from "lodash-es"

const isSimple = (value: any) => isString(value) || isNumber(value) || isDate(value) || isBoolean(value)

/**
 * Converts the value to a `Date` including time. Supported values:
 * - Any string recognized by `Date.parse`.
 * - An array of the form `[years, months, days, hours, minutes, seconds]`. This is interpreted as a UTC date.
 *
 * @argument {any} value The value to convert to a date.
 * @returns {Date | undefined}
 */
export const datetime: ConversionFunction<Date> = value => {
	if (isSimple(value)) {
		const d = new Date(value)
		return !isNaN(d.getFullYear()) ? d : undefined
	} else if (isArray(value)) {
		const [year, month, day, h, m, s] = value.map(numeric)
		if (year && month && day && [h, m].every(part => !isUndefined(part))) {
			return new Date(Date.UTC(year, month - 1, day, h, m, s || 0))
		}
	}
	return undefined
}

/**
 * Converts the value to a truncated `Date`. This means that the time portion of the date will
 * be set to 00:00:00 in the local timezone. Supported values:
 * - A string of the form `yyyy-mm-dd`.
 * - An array of the form `[years, months, days]`.
 *
 * @argument {any} value The value to convert to a date.
 * @returns {Date | undefined}
 */
export const date: ConversionFunction<Date> = value => {
	const [year, month, day] = isSimple(value) ? toString(value).split("-").map(numeric)
		: isArray(value) ? value.map(numeric)
		: [undefined, undefined, undefined]

	if (year && month && day) {
		return new Date(year, month - 1, day, 0, 0, 0, 0)
	}
	return undefined
}

export const alphanumeric: ConversionFunction<string> = value => isSimple(value) ? value.toString() : undefined

export const numeric: ConversionFunction<number> = value => {
	const x = isSimple(value) && !isEmpty(toString(value)) ? toNumber(value) : NaN
	return !isNaN(x) ? x : undefined
}

export const bool: ConversionFunction<boolean> = value => {
	if (!isNil(value)) {
		if (value.toString() === "true") {
			return true
		}
		if (value.toString() === "false") {
			return false
		}
	}
	return undefined
}

export const file: ConversionFunction<File> = value => value instanceof File ? value : undefined

export const pairOf = <a, b>(fnA: ConversionFunction<a>, fnB: ConversionFunction<b>): ConversionFunction<[a, b]> => value =>
	Array.isArray(value) && value.length === 2 && !isUndefined(fnA(value[0])) && !isUndefined(fnB(value[1])) ? value as [a, b] : undefined
