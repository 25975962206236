import {ConversionMap} from "lib/types/import"
import {one, many, mandatory} from "lib/import/extract"
import {numeric, date, alphanumeric, bool} from "lib/import/convert"
import {
	exchangeTarget,
	channel,
	legalCommunication,
	statePensionSource,
	privateBenefitSource,
	privateBenefitType,
	fiscalType,
	externalCoverCause,
	externalCoverSource,
	externalCoverType,
	beneficiary,
	trigger
} from "lib/import/personalConvert"
import {
	Participant,
	Choices,
	Bridging,
	Exchange,
	HighLow,
	Parttime,
	Communication,
	Contact,
	Address,
	Pension,
	ExternalCover,
	StatePension,
	Register,
	CommunicationContact,
	SinglePartnerAmounts,
	PrivateBenefit
} from "./types"
import { AOW } from "store/pensiondetails/types"
import { equalization } from "store/policyholder/conversion"

const required = true
const optional = false

// Choices
export const bridging: ConversionMap<Bridging> = {
	amount: ["amount", numeric, required]
}

export const exchange: ConversionMap<Exchange> = {
	percentage: ["percentage", numeric, required],
	target: ["target", exchangeTarget, required]
}

export const highlow: ConversionMap<HighLow> = {
	duration: ["duration", numeric, optional],
	endDate: ["endDate", date, optional],
	highPercentage: ["highPercentage", numeric, required],
	lowPercentage: ["lowPercentage", numeric, required]
}

export const parttime: ConversionMap<Parttime> = {
	date: ["date", date, required],
	pensionPercentage: ["pensionPercentage", numeric, optional],
	workPercentage: ["workPercentage", numeric, optional]
}

export const choices: ConversionMap<Choices> = {
	bridging: ["bridging", one(bridging), optional],
	exchange: ["exchange", one(exchange), optional],
	highLow: ["highlow", one(highlow), optional],
	parttime: ["parttime", one(parttime), optional],
	pensionDate: ["pensionDate", date, required],
	referenceDate: ["referenceDate", date, required]
}

// Communication
export const communication: ConversionMap<Communication> = {
	channel: ["channel", channel, optional],
	documents: ["documents", bool, optional],
	legal: ["legal", legalCommunication, optional],
	news: ["news", bool, optional]
}

// Contact
export const address: ConversionMap<Address> = {
	city: ["city", alphanumeric, required],
	countryCode: ["countryCode", alphanumeric, required],
	number: ["number", numeric, required],
	postalCode: ["postalCode", alphanumeric, required],
	regionCode: ["regionCode", alphanumeric, required],
	street: ["street", alphanumeric, required],
	suffix: ["suffix", alphanumeric, required]
}

export const partialContact: ConversionMap<Partial<Contact>> = {
	address: ["address", one(address), optional],
	email: ["email", alphanumeric, optional],
	telephone: ["telephone", alphanumeric, optional]
}

export const contact: ConversionMap<Contact> = {
	address: ["address", one(address), required],
	email: ["email", alphanumeric, optional],
	telephone: ["telephone", alphanumeric, required]
}

// Pension
export const register: ConversionMap<Register> = {
	identifier: ["identifier", alphanumeric, required],
	provider: ["provider", alphanumeric, required]
}

export const externalCover: ConversionMap<ExternalCover> = {
	beneficiary: ["beneficiary", beneficiary, required],
	trigger: ["trigger", trigger, required],
	startDate: ["startDate", date, optional],
	title: ["title", alphanumeric, optional],
	equalization: ["equalization", one(equalization), optional],
	currency: ["currency", alphanumeric, required],
	id: ["id", alphanumeric, required],
	endDate: ["endDate", date, optional],
	referenceDate: ["referenceDate", date, required],
	accrued: ["accrued", numeric, required],
	benefit: ["benefit", numeric, required],
	cause: ["cause", externalCoverCause, required],
	register: ["register", one(register), optional],
	source: ["source", externalCoverSource, required],
	type: ["type", externalCoverType, required]
}

export const singlePartnerAmounts: ConversionMap<SinglePartnerAmounts> = {
	partner: ["partner", numeric, optional],
	single: ["single", numeric, optional]
}

export const statePension: ConversionMap<StatePension> = {
	accrued: ["accrued", one(singlePartnerAmounts), required],
	referenceDate: ["referenceDate", date, required],
	startDate: ["startDate", date, required],
	source: ["source", statePensionSource, required],
	benefit: ["benefit", one(singlePartnerAmounts), required]
}

export const privateBenefit: ConversionMap<PrivateBenefit> = {
	benefit: ["benefit", numeric, required],
	source: ["source", privateBenefitSource, required],
	startDate: ["startDate", date, required],
	referenceDate: ["referenceDate", date, required],
	endDate: ["endDate", date, optional],
	id: ["id", alphanumeric, required],
	currency: ["currency", alphanumeric, required],
	name: ["name", alphanumeric, required],
	type: ["type", privateBenefitType, required],
	fiscal: ["fiscal", fiscalType, required]
}

const aow: ConversionMap<AOW> = {
	title: ["nonexistent", () => "AOW", required],
	id: ["id", alphanumeric, optional],
	civilStatusNpr: ["civilStatusNpr", alphanumeric, optional],
	typeOfAOW: ["nonexistent", () => undefined, optional],
	single: ["benefit.single", numeric, required],
	partner: ["benefit.partner", numeric, required],
	removable: ["removable", bool, optional],
	editable: ["editable", bool, optional],
	startDate: ["startDate", date, required],
	referenceDate: ["referenceDate", date, required]
}

export const pension: ConversionMap<Pension> = {
	choices: ["choices", one(choices), optional],
	external: ["external", many(externalCover), optional],
	state: ["state", one(aow), optional],
	private: ["private", many(privateBenefit), optional],
	creationDate: ["creationDate", date, optional]
}

// Participant
export const participant: ConversionMap<Participant> = {
	id: ["id", alphanumeric, required],
	choices: ["choices", one(choices), optional],
	communication: ["communication", one(communication), optional],
	contact: ["contact", one(contact), required],
	locale: ["locale", alphanumeric, required],
	pension: ["pension", one(pension), required],
	policyholderId: ["policyholderId", alphanumeric, required],
	// TODO: make a `Map<string, any>` ConversionFunction
	state: ["state", (v: any) => v, required]
}

export const communicationContactConversion: ConversionMap<CommunicationContact> = {
	communication: ["communication", one(communication), optional],
	contact: ["contact", one(contact), optional]
}

export const convert = mandatory(one(participant))
