import StorageController from "./StorageController"
import {StorageOptions} from "lib/types/storage"

// Supply a StorageController and corresponding options using session storage.

const storage: Storage = !process.env.SERVER
	? sessionStorage
	: {
		get length() {
			return 0
		},
		clear() {
			// No action.
		},
		getItem(_key: string) {
			return null
		},
		key(_index: number) {
			return null
		},
		removeItem(_key: string) {
			// No action.
		},
		setItem(_key: string, _value: string) {
			// No action.
		}
	}

export const storageController = new StorageController(storage)

export const storageOptions: StorageOptions = {
	storage: storageController
}
