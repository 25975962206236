












import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class PageSlide extends Vue {
	@Prop({type: Boolean, default: false}) isVisible!: boolean
	@Prop({type: String, default: "primary"}) type!: string
}
